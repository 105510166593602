import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'reducers';
import { shallowEqual } from 'react-redux';
import PreviewPlayer from 'components/PreviewPlayer';
import RestartButton from 'components/RestartButton';
import { useWindowSize, usePreloadImage } from 'utils/hooks';
import { BREAKPOINTS, COLLECTION_SIZE } from 'utils/constants';
import visibilityManager from 'utils/visibilityManager';
import {
  HeroContainer,
  Background,
  Container,
  Container16x9,
  ContainerMax,
  Gradient,
  RestartBtnContainer,
  Overlay,
} from './Styles';

export function Hero({
  src,
  imageSize = '',
  overlay,
  children,
  className,
  imagePosition,
  gradientBackground,
  viewable,
  active,
  onTrailerEnd,
  enableTrailer,
}) {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const heroContainerRef = useRef(null);
  const heroComponentRef = useRef(null);
  const [isImgVisible, setIsImgVisible] = useState(true);
  const [showPlayer, setShowPlayer] = useState(true);
  const windowSize = useWindowSize();
  const imageLoaded = usePreloadImage(src, width, height);
  // allow player to render only if component is/was visible
  const [allowPlayerRender, setAllowPlayerRender] = useState(false);

  const {
    isLoggedIn,
    videoPreview,
  } = useSelector(state => ({
    isLoggedIn: state.auth.isLoggedIn,
    videoPreview: state.settings.features.videoPreview,
  }), shallowEqual);

  const isVideoPreviewActive = active // active tab
    && enableTrailer
    && !!videoPreview // preview is enabled in the console
    && imageSize === COLLECTION_SIZE.sixteenNine // only for new 16x9 featured
    && windowSize.width >= BREAKPOINTS.sm; // only if width > 600px

  useEffect(() => {
    const { clientWidth, clientHeight } = heroContainerRef.current || {};
    if (width !== clientWidth) {
      setWidth(clientWidth);
    }
    if (height !== clientHeight) {
      setHeight(clientHeight);
    }
  }, []);

  useEffect(() => {
    const el = heroComponentRef.current;
    if (!el || allowPlayerRender || !isVideoPreviewActive) {
      return () => {};
    }
    // render Preview Player when component became visible
    visibilityManager.add(el, v => setAllowPlayerRender(v));
    return () => visibilityManager.remove(el);
  }, [allowPlayerRender, isVideoPreviewActive]);

  const onImageAnimationEnd = useCallback(() => {
    // remove player and show replay button
    if (isImgVisible && active) {
      setShowPlayer(false);
    }
  }, [isImgVisible, active]);

  return (
    <HeroContainer
      innerRef={heroComponentRef}
      imageSize={imageSize}
      className={className}
    >
      {imageSize === COLLECTION_SIZE.sixteenNine ? (
        <Container16x9>
          <ContainerMax
            innerRef={heroContainerRef}
            height={heroComponentRef.current?.offsetHeight}
          >
            {isVideoPreviewActive && allowPlayerRender && showPlayer && (
              <PreviewPlayer
                onTrailerEnd={onTrailerEnd}
                preferredBitrate="high"
                zoom={videoPreview.zoom}
                toggleImg={setIsImgVisible}
                trailerUrl={viewable?.trailer}
                trailers={isLoggedIn ? viewable?.trailers : []}
              />
            )}
            <Background
              className="videoBanner"
              src={imageLoaded ? src : ''}
              imagePosition={imagePosition}
              width={width}
              height={height}
              visible={isImgVisible}
              onTransitionEnd={onImageAnimationEnd}
            />
            {isVideoPreviewActive && !showPlayer && (
              <RestartBtnContainer className="restartBtnContainer">
                <RestartButton onClick={() => setShowPlayer(true)} />
              </RestartBtnContainer>
            )}

            <Overlay overlay={overlay}>
              <Gradient background={gradientBackground} />
            </Overlay>
          </ContainerMax>
        </Container16x9>
      ) : (
        <Container
          innerRef={heroContainerRef}
          overlay={overlay}
          imageSize={imageSize}
        >
          <Background
            className="videoBanner"
            src={imageLoaded ? src : ''}
            imagePosition={imagePosition}
            width={width}
            height={height}
            visible={isImgVisible}
          />
          <Overlay overlay={overlay}>
            <Gradient background={gradientBackground} />
          </Overlay>
        </Container>
      )}
      {children}
    </HeroContainer>
  );
}
Hero.propTypes = {
  src: PropTypes.string,
  imageSize: PropTypes.string,
  overlay: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  imagePosition: PropTypes.oneOf(['top', 'center']),
  gradientBackground: PropTypes.string,
  viewable: PropTypes.object,
  active: PropTypes.bool,
  onTrailerEnd: PropTypes.func,
  enableTrailer: PropTypes.bool,
};
