import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { sessionStorage } from 'utils/storage';
import createComponent from 'styles/fela/createComponent';
import { BREAKPOINTS, ENTITLEMENT_TYPES, PAYMENT_PROVIDERS } from 'utils/constants';
import {
  Link,
  Notification,
  Text,
} from 'components';
import * as packsActions from 'actions/packs';
import * as billingActions from 'actions/billing';
import { differenceInCalendarDays, isAfter, isDate } from 'date-fns';
import { EntitlementStatus } from 'utils/constants';
import { isTimestampInFuture } from 'utils/time';
import { useAnalytics } from 'components/Tracking';

const Container = createComponent(() => ({
  maxWidth: `${BREAKPOINTS.md}px`,
  textAlign: 'center',
  fontWeight: '600',
}));

const PaymentWarningNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    isLoggedIn,
    entitledPack,
    discounts,
    withPayment,
    providerType,
    paymentMethod,
    isCookieBannerShowing,
    daysBeforeBilling,
  } = useSelector(({ auth, packs, settings, billing, cookie }) => ({
    isLoggedIn: auth.isLoggedIn,
    entitledPack: packs.entitled || [],
    discounts: packs.discounts,
    withPayment: !!settings.features.payment,
    providerType: settings.features.payment?.provider?.type,
    paymentMethod: billing.paymentMethod,
    cookiePolicyUrl: settings.features?.cookiePolicy?.url,
    isCookieBannerShowing: cookie.cookiePolicyNotificationShown,
    daysBeforeBilling: settings.features?.ccExpiryNotice?.daysBeforeBilling,
  }), shallowEqual);
  const dispatch = useDispatch();
  const [ccDaysLeft, setCcDaysLeft] = useState();
  const analytics = useAnalytics();

  const entitlements = useMemo(() => {
    return entitledPack.filter(
      entitlement => entitlement.__typename === ENTITLEMENT_TYPES.SUBSCRIBE
        && (
          [EntitlementStatus.active, EntitlementStatus.expired].includes(entitlement.status)
          || (
            entitlement.status === EntitlementStatus.cancelled
            && entitlement.endDate
            && isTimestampInFuture(entitlement.endDate.valueOf())
          )
        )
        && entitlement.netPrice > 0,
    );
  }, [entitledPack]);

  const ccExpDate = useMemo(() => {
    if (!paymentMethod?.data) return undefined;
    const { data: { ccExpYear, ccExpMonth } } = paymentMethod;
    const date = new Date(ccExpYear, ccExpMonth, 0);
    return date;
  }, [paymentMethod]);

  useEffect(() => {
    if (isLoggedIn && withPayment && !sessionStorage.getItem('paymentWarningNotificationShown') && daysBeforeBilling) {
      dispatch(billingActions.getPaymentMethod());
      dispatch(packsActions.fetchEntitledPacks());
    }
  }, [isLoggedIn, withPayment]);

  useEffect(() => {
    if (
      !sessionStorage.getItem('paymentWarningNotificationShown') &&
      withPayment && entitlements.length && !isCookieBannerShowing &&
      isDate(ccExpDate) && daysBeforeBilling
    ) {
      const showPaymentWarning = entitlements.some((pack) => {
        const discount = discounts.find(promo => promo.pack === pack.id);
        const nextBillingDate = discount?.endDate || pack.endDate;
        if (!isDate(nextBillingDate)) return false;
        const daysToNextBillingDate = differenceInCalendarDays(nextBillingDate, new Date());
        return daysToNextBillingDate <= daysBeforeBilling && !isAfter(ccExpDate, nextBillingDate);
      });

      if (showPaymentWarning) {
        const daysToCcExpDate = differenceInCalendarDays(ccExpDate, new Date());
        setIsVisible(true);
        setCcDaysLeft(daysToCcExpDate);
      }
    }
  }, [withPayment, entitlements, isCookieBannerShowing, ccExpDate, discounts, daysBeforeBilling]);

  useEffect(() => {
    if (isVisible) {
      // send analytics when component is shown
      analytics.onVisible({
        component: 'CreditCardExpiryNotification',
      });
    }
  }, [isVisible]);

  const dismissNotification = () => {
    sessionStorage.setItem('paymentWarningNotificationShown', true);
    setIsVisible(false);
  };

  const close = () => {
    analytics.onClick({
      component: 'CreditCardExpiryNotification',
      action: 'click',
      clickType: 'action',
      extra: {
        area: 'close_icon',
      },
    });
    dismissNotification();
  };

  const onLinkClick = () => {
    analytics.onClick({
      component: 'CreditCardExpiryNotification',
      action: 'click',
      clickType: 'navigation',
      extra: {
        area: 'link',
      },
    });
    dismissNotification();
  };

  const link =
    <Link
      key="paymentWarningLinkMessage"
      to={{
        name: providerType === PAYMENT_PROVIDERS.ADYEN_DROPIN
          ? 'settings-payment-setup'
          : 'settings-payment',
      }}
      id="paymentWarning.linkMessage"
      onClick={onLinkClick}
    />;

  if (!isVisible) {
    return null;
  }

  return (
    <Notification onClose={close} bottom fullWidth>
      <Container data-nosnippet>
        {ccDaysLeft >= 0 ? (
          <Text
            id="paymentWarning.expiringMessage"
            values={{ days: ccDaysLeft, link }}
          />
        ) : (
          <Text
            id="paymentWarning.expiredMessage"
            values={{ link }}
          />
        )}
      </Container>
    </Notification>
  );
};

export default PaymentWarningNotification;
