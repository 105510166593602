import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import {
  smallScreen,
  smallScreenL,
  responsiveMd,
} from 'styles/fela/mixins';
import { CloseIcon } from 'components/Icons';

const Container = createComponent(({ theme, withWidth, rounded }) => ({
  position: 'relative',
  zIndex: theme.zIndex.modal,
  color: theme.color.modalTextColor,

  pointerEvents: 'none', // allow to click throught the container

  minWidth: '15rem',
  maxWidth: '95vw',

  marginLeft: theme.margin.xlarge,
  marginRight: theme.margin.xlarge,
  marginTop: theme.margin.fine,
  marginBottom: theme.margin.fine,

  display: 'flex',
  alignItems: 'center',

  extend: [
    {
      condition: !!withWidth,
      style: {
        width: theme.size.modal[withWidth] || withWidth,
      },
    },
    {
      condition: rounded,
      style: {
        borderRadius: theme.radius.big,
      },
    },
    smallScreenL({
      alignItems: 'baseline',
    }),
    smallScreen({
      marginLeft: 0,
      marginRight: 0,
      alignItems: 'center',
    }),
    responsiveMd({
      minWidth: '30rem',
    }),
  ],
}), 'div', ['withShadow', 'withWidth', 'rounded']);

// Container provides the base matte color, and the color canvas overlays
// the final background color over it, to remove the 0.3 transparency

const ColorCanvasContents = createComponent(({ theme, withOutline, hasHeroImage }) => ({
  position: 'relative',
  zIndex: theme.zIndex.modalScreen,
  borderRadius: 'inherit',
  background: theme.color.modalBackground,
  padding: theme.margin.large,
  width: '100%',
  extend: [
    {
      condition: withOutline,
      style: {
        border: `2px solid ${theme.color.brand}`,
      },
    },
    {
      condition: hasHeroImage,
      style: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  ],
}), 'div', ['withOutline', 'hasHeroImage']);

// Show the children of the modal above the color canvas
const ZIndexContainer = createComponent(({ theme }) => ({
  position: 'relative',
  zIndex: theme.zIndex.modalContent,
}));

const XContainer = createComponent(({ theme, color }) => ({
  color: theme.color[color],
  borderRadius: '50%',
  cursor: 'pointer',
  width: theme.size.modalXButton,
  height: theme.size.modalXButton,
  display: 'flex',
  margin: 'auto',
  paddingLeft: '1px', // to make it look more "centered" (optical illusion)
  position: 'absolute',
  right: theme.margin.small,
  top: theme.margin.small,
  zIndex: theme.zIndex.modalContent + 1,
}), 'div', ['color']);

const BackgroundContainer = createComponent(({ theme, withShadow }) => ({
  background: theme.color.modalBaseBackground,
  borderRadius: 'inherit',
  pointerEvents: 'auto', // make it possible to click on modal
  margin: 'auto',
  width: '100%', // for IE11
  extend: [
    {
      condition: withShadow,
      style: {
        boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.24)',
      },
    },
    smallScreen({
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'baseline',
    }),
  ],
}), 'div', ['withShadow']);

const HeroImage = createComponent(({ theme, url }) => ({
  backgroundImage: `url(${url})`,
  display: 'flex',
  width: '100%',
  borderTopLeftRadius: 'inherit',
  borderTopRightRadius: 'inherit',
  aspectRatio: theme.size.image16x9,
  backgroundSize: 'cover',
  color: theme.color.modalImageTextColor,
  padding: `${theme.margin.large} ${theme.margin.xlarge}`,
  fontSize: theme.fontSize.xxxlarge,
  fontWeight: 'bold',
  textShadow: theme.textShadow.modalHero,

  extend: [
    smallScreen({
      fontSize: theme.fontSize.xlarge,
    }),
  ],
}), 'div', ['url']);

export default function ModalContainer({
  withShadow = false,
  withOutline = false,
  withWidth,
  rounded = true,
  children,
  onClickClose,
  className,
  imageUrl,
  message,
}) {
  return (
    <Container
      className={className}
      withWidth={withWidth}
      rounded={rounded}
    >
      <BackgroundContainer withShadow={withShadow}>
        {onClickClose && (
          <XContainer color={imageUrl ? 'modalImageTextColor' : 'modalTextColor'}>
            <CloseIcon size="100%" onClick={onClickClose} />
          </XContainer>
        )}
        {imageUrl && (
          <HeroImage url={imageUrl}>
            {message}
          </HeroImage>
        )}
        <ColorCanvasContents withOutline={withOutline} hasHeroImage={imageUrl}>
          <ZIndexContainer>
            {children}
          </ZIndexContainer>
        </ColorCanvasContents>
      </BackgroundContainer>
    </Container>
  );
}

ModalContainer.propTypes = {
  className: PropTypes.string,
  withShadow: PropTypes.bool,
  withOutline: PropTypes.bool,
  withWidth: PropTypes.string,
  rounded: PropTypes.bool,
  onClickClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  imageUrl: PropTypes.string,
  message: PropTypes.string,
};
