import { OFFER_TYPES, OfferType } from 'utils/constants';
import { Offer } from 'types';

// order is defined in https://magine.atlassian.net/browse/MDM-8790
const allOfferTypes = [
  OFFER_TYPES.DefaultType, // should be on a first place
  OFFER_TYPES.SubscribeType,
  OFFER_TYPES.BuyType,
  OFFER_TYPES.RentType,
  OFFER_TYPES.PassType,
];

type Info = {
  types: OfferType,
  offer: Offer,
  isSingleOffer: boolean,
};
const sortByPrice = (a: Offer, b: Offer) => a.priceInCents - b.priceInCents;

export function getOfferTypeInfo(offers: Offer[], withPayments = true): Info[] {
  const info: Info[] = [];
  const offerTypes = withPayments ? allOfferTypes : [OFFER_TYPES.DefaultType];

  for (const t of offerTypes) {
    const offersT = offers.filter(o => o.__typename === t).sort(sortByPrice);

    if (offersT.length) {
      info.push({
        types: t,
        offer: offersT[0],
        isSingleOffer: offersT.length === 1,
      });

      // only allow default offer if it exists
      if (t === OFFER_TYPES.DefaultType) break;
    }
  }

  return info;
}

type ButtonProps = {
  types: string,
  offer?: Offer,
  isSingleOffer?: boolean,
};

export function getButtonProps(offers: Offer[] = [], withPayment = true): ButtonProps[] {
  const buttonInfo = [];
  const infoByType = getOfferTypeInfo(offers, withPayment);
  // maximum 2 btns allowed
  if (infoByType.length <= 2) {
    // copy btns without changes
    buttonInfo.push(...infoByType);
  } else {
    // add first btn without changes
    buttonInfo.push(infoByType.shift()!);
    // merge last btns in one Purchase btn
    buttonInfo.push({
      types: infoByType.map(oTypeInfo => oTypeInfo.types).join(','),
    });
  }

  return buttonInfo;
}
