import React from 'react';
import { TRANSACTION_STATUS } from 'utils/constants';
import { flattenEdges } from 'utils/helpers';
import {
  Text,
  Heading,
  Hidden,
  Metadata,
} from 'components';
import { useGetPaymentsQuery } from './payments.generated';
import { TTransaction } from './types';
import SettingsContainer from '../SettingsContainer';
import Transaction from './Transaction';
import TransactionMobile from './TransactionMobile';
import {
  TableRow,
  TableCell,
} from './Styles';

const TransactionsView = (): JSX.Element | null => {
  const {
    loading,
    error,
    data,
  } = useGetPaymentsQuery({
    errorPolicy: 'ignore',
  });

  if ((loading && !data) || error) {
    return null;
  }

  const transactions: TTransaction[] = flattenEdges(data?.viewer.paymentHistory)
    .filter(
      (transaction: TTransaction) => (
        transaction.netPrice > 0 && transaction.status !== TRANSACTION_STATUS.INITIAL
      ),
    ).reverse();

  return (
    <SettingsContainer wide>
      <Metadata title="transactions" />

      <Heading
        id="transactions"
        letterSpacing="2.3px"
        mb="medium"
      />

      {transactions.length === 0 ? (
        <Text id="transactions.empty" />
      ) : (
        <>
          <Hidden to="md">
            <table>
              <tbody>
                <TableRow style={{ fontWeight: 'bold' }}>
                  <TableCell>
                    <Text id="transaction.date" />
                  </TableCell>
                  <TableCell>
                    <Text id="transaction.offer" />
                  </TableCell>
                  <TableCell>
                    <Text id="transaction.status" />
                  </TableCell>
                  <TableCell>
                    <Text id="transaction.price" />
                  </TableCell>
                  <TableCell align="center">
                    <Text id="transaction.receipt" />
                  </TableCell>
                </TableRow>

                {transactions.map((transaction, index) => (
                  <Transaction
                    key={`${transaction.timestamp}:${transaction.status}`}
                    transaction={transaction}
                    withHeader={index > 0}
                  />
                ))}
              </tbody>
            </table>
          </Hidden>

          <Hidden from="md">
            <table>
              <tbody>
                <TableRow style={{ paddingTop: 0 }} />
                {transactions.map(transaction => (
                  <TransactionMobile
                    key={`${transaction.timestamp}:${transaction.status}`}
                    transaction={transaction}
                  />
                ))}
              </tbody>
            </table>
          </Hidden>
        </>
      )}

    </SettingsContainer>
  );
};

export default TransactionsView;
