import React from 'react';
import PropTypes from 'prop-types';
import {
  VIEWABLE_TYPES,
} from 'utils/constants';
import { extractLiveEventTime } from 'utils/live-event';
import { extractUpcomingStartTime } from 'utils/upcoming';
import { getChannelLogoData } from 'utils/channel';
import { getLinkParams, getPlayableProp } from 'utils/helpers';
import ChannelLogo from 'components/ChannelLogo/ChannelLogo';
import FixedChannelLogo from 'components/ChannelLogo/FixedChannelLogo';
import {
  Image,
  Box,
  LiveLabel,
  LiveInfo,
  VideoProgressBar,
  imageTypes,
  CleanLink,
} from 'components';
import { ITEM_MODE } from 'components/VideoItems/constants';
import { viewableShape } from 'components/VideoItems/commonPropTypes';
import ListTitles from 'components/VideoItems/RegularItem/ListTitles';
import GridTitles from 'components/VideoItems/RegularItem/GridTitles';

const positionRelated = {
  position: 'relative',
};

function RegularItem(props) {
  const {
    viewable,
    mode,
    disableLiveInfo,
    hideChannelLogo,
    refreshData,
  } = props;

  const handleItemClick = () => {
    const {
      id,
      playableId,
    } = getLinkParams(viewable);

    if (props.onClick) {
      props.onClick(id, playableId);
    }
  };

  const liveEventTime = extractLiveEventTime(viewable);
  const isChannel = viewable.__typename === VIEWABLE_TYPES.Channel;
  const channel = getChannelLogoData(viewable);
  const watchOffset = getPlayableProp(viewable, 'watchOffset');
  const upcomingTimestamp = extractUpcomingStartTime(viewable);

  const poster = isChannel ? (
    <ChannelLogo
      alt={viewable.title}
      src={viewable.logoDark}
      withBackground
    />
  ) : (
    <Image
      alt={viewable.title}
      hasShadow
      src={viewable.poster || viewable.banner}
      type="poster"
      fullWidth={mode === ITEM_MODE.GRID}
      rounded
    />
  );

  return (
    <Box
      flexBox
      alignItems="flex-start"
      as={CleanLink}
      to={{
        name: 'watch',
        params: getLinkParams(viewable),
      }}
      mb={mode === ITEM_MODE.LIST ? 'large' : undefined}
      column={mode === ITEM_MODE.GRID}
      onClick={handleItemClick}
      width="100%"
      className="e2e-asset"
      position="relative"
      flexDirection="column"
      sm-flexDirection={mode === ITEM_MODE.GRID ? 'column' : 'row'}
    >
      <Box
        position="relative"
        width={mode === ITEM_MODE.GRID ? '100%' : undefined}
        mb={upcomingTimestamp && [ITEM_MODE.GRID, ITEM_MODE.SLIDER].includes(mode) ? 'fine' : undefined}
      >
        <Box position="relative">
          {liveEventTime && !disableLiveInfo && (
            <Box
              position="absolute"
              top="0"
              right="0.8rem"
              zIndex={2}
            >
              <LiveLabel liveEventTime={liveEventTime} />
            </Box>
          )}

          {poster}

          {!hideChannelLogo && !isChannel && channel && (
            <FixedChannelLogo
              alt={channel.title}
              src={channel.logoDark}
              withBackground
              size="45%"
            />
          )}

          {!viewable.entitlement || watchOffset > 0 ? (
            <Box
              column
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              alignItems="flex-start"
            >
              {watchOffset > 0 && (
                <VideoProgressBar
                  offset={watchOffset}
                  duration={getPlayableProp(viewable, 'duration')}
                  extend={positionRelated}
                />
              )}
            </Box>
          ) : null}
        </Box>
        {liveEventTime && !disableLiveInfo && (
          <Box mt="fine">
            <LiveInfo liveEventTime={liveEventTime} onlyToday />
          </Box>
        )}
      </Box>
      {mode === ITEM_MODE.GRID && <GridTitles viewable={viewable} />}
      {mode === ITEM_MODE.LIST && (
        <ListTitles
          viewable={viewable}
          itemHeight={imageTypes.poster.height}
          refreshData={refreshData}
        />
      )}
    </Box>
  );
}

RegularItem.propTypes = {
  viewable: viewableShape.isRequired,
  onClick: PropTypes.func,
  refreshData: PropTypes.func,
  mode: PropTypes.string,
  disableLiveInfo: PropTypes.bool,
  hideChannelLogo: PropTypes.bool,
};

RegularItem.defaultProps = {
  mode: ITEM_MODE.LIST,
};

export default React.memo(RegularItem);
