import { ApolloCache } from '@apollo/client';
import { ViewableType } from 'types';
import VIEWABLE_FRAGMENT from './queries/viewableFragment.gql';

export { useAddToWatchlistMutation } from './queries/watchlistAdd.generated';
export { useRemoveFromWatchlistMutation } from './queries/watchlistRemove.generated';

export const setInMyList = (
  cache: ApolloCache<any>,
  viewable: ViewableType,
  inMyList: boolean,
): void => {
  cache.writeFragment({
    id: `ViewableInterface:${viewable.id}`,
    fragment: VIEWABLE_FRAGMENT,
    data: {
      __typename: viewable.__typename,
      id: viewable.id,
      inMyList,
    },
  });
  cache.writeFragment({
    id: `Carousel:ViewableInterface:${viewable.id}`,
    fragment: VIEWABLE_FRAGMENT,
    data: {
      __typename: viewable.__typename,
      id: viewable.id,
      inMyList,
    },
  });
};
