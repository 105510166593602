import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import ModalContainer from 'components/Modal/ModalContainer';
import { Box } from 'components/StyledSystem/Box';
import { ModalHeading } from 'components/Modal/ModalStyles';
import ResetPin from './ResetPin';

const ResetPinModal = (props) => {
  const {
    onSuccess,
    onClose,
    preventPinReset,
  } = props;

  return (
    <Modal onDismiss={onClose}>
      <ModalContainer
        onClickClose={onClose}
        withWidth="medium"
        withShadow
        dark
      >
        <Box column>
          <Box my="medium">
            <ModalHeading id="appSettings.sendNewPinCodeModalHeader" />
          </Box>

          <ResetPin
            preventPinReset={preventPinReset}
            onSuccess={onSuccess}
            onClose={onClose}
          />
        </Box>
      </ModalContainer>
    </Modal>
  );
};

ResetPinModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  preventPinReset: PropTypes.bool,
};

export default ResetPinModal;
