import React, { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import * as authActions from 'actions/auth';
import { setResellerBrandLogo } from 'actions/settings';
import * as userActions from 'actions/user';
import { I18nContext } from 'components/I18n';
import { useAnalytics } from 'components/Tracking';
import {
  PrimaryButton,
  GeneralError,
  Heading,
  Link,
  Text,
  Box,
  Panel,
  PanelContent,
  PanelWrapper,
  Metadata,
} from 'components';
import { InputField } from 'components/Forms';
import validate from 'utils/validate';
import logger from 'utils/logger';
import { PLATFORMS, PLATFORMS_NAME } from 'utils/constants';
import PlaceholderWithSpinner from 'components/PlaceholderWithSpinner/PlaceholderWithSpinner';

const loginFieldsErrorMessages = {
  identity: 'validate.emailRequired',
  accessKey: 'validate.passRequired',
};

const inputFieldHeight = 109;

function LoginViewComponent() {
  const analytics = useAnalytics();
  const i18n = useContext(I18nContext);
  const [inProgress, setInProgress] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit, setError } = methods;

  const dispatch = useDispatch();
  const {
    isCreateAccountEnabled,
    loginMethod,
    theme,
    location,
    brandName,
    devicePlatform,
  } = useSelector(({ settings, country, router }) => ({
    isCreateAccountEnabled: country.isCreateAccountEnabled,
    loginMethod: settings.features.loginMethod,
    theme: settings.brand.theme,
    location: router.location,
    brandName: settings.brand.name,
    devicePlatform: router.location.params?.platform,
  }), shallowEqual);
  const apolloClient = useApolloClient();

  const login = async (data) => {
    setGeneralError(null);
    setInProgress(true);
    try {
      await dispatch(authActions.login(data));

      analytics.onLoginSuccess('email');

      await setResellerBrandLogo(apolloClient, theme, dispatch);

      await dispatch(userActions.getUser());
      await dispatch(authActions.openInitialView());
    } catch (e) {
      logger.error('Unable to login', e);
      // stop spinner only if login failed
      setInProgress(false);

      if (e.response?.status === 401 || e.message?.includes('401')) {
        return setGeneralError(i18n.formatText('login.errBadCredentials'));
      }

      if (e.response?.status === 400) {
        for (const validationError of (await e.response.json()).errors) {
          setError(validationError.field, {
            type: 'server',
            message: validationError.reason,
          });
        }
      }

      return setGeneralError(i18n.formatText('login.errLoginFailed'));
    }

    return null;
  };

  const getInputFields = () => {
    return loginMethod.inputFields.map(field => (
      <InputField
        key={field.key}
        className={`e2e-${field.key}`}
        field={field}
        validations={{
          required: field.required
            ? validate.required(loginFieldsErrorMessages[field.key])
            : undefined,
        }}
      />
    ));
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const platformDevice = i18n.formatText(devicePlatform === PLATFORMS.MOVISTAR ? 'decoder' : 'device');

  return (
    <PanelWrapper>
      <Panel textAlign="center">
        <Metadata
          title={loginMethod.title}
          description={loginMethod.subtitle}
        />

        <Heading mb="large">
          {loginMethod.title}
        </Heading>

        <Heading fontSize="medium" mb="xlarge" as="h2">
          {devicePlatform
            ? (
              <Text
                id="login.subtitle.platform"
                values={{
                  brandName,
                  platform: PLATFORMS_NAME[devicePlatform?.toUpperCase()],
                  device: platformDevice,
                }}
              />
            )
            : loginMethod.subtitle}
        </Heading>

        <FormProvider {...methods}>
          <PanelContent centered as="form" onSubmit={handleSubmit(login)} method="POST" noValidate>
            {isLoading ? (<PlaceholderWithSpinner height={loginMethod.inputFields.length * inputFieldHeight} />) : getInputFields()}

            {generalError && (
              <GeneralError>{generalError}</GeneralError>
            )}

            <Box my="medium">
              <PrimaryButton
                variant="brand"
                type="submit"
                className="e2e-log-in-btn"
                showSpinner={inProgress}
              >
                {/* MDM-11096 wrap text to avoid issues with Google Translate */}
                <span>
                  {loginMethod.doneLabel}
                </span>
              </PrimaryButton>
            </Box>

            {loginMethod.forgotPassword && (
              <Box mb="large">
                <Link
                  to={{ name: 'forgot-password' }}
                  fontSize="small"
                  className="e2e-forgot-password"
                >
                  {loginMethod.forgotPassword.triggerLabel}
                </Link>
              </Box>
            )}

            {isCreateAccountEnabled && (
              <Text
                bold
                color="secondary"
                fontSize="medium"
                id="noAccountCreateAccount"
                values={{
                  createAccountLink: (
                    <Link
                      key="createAccountLink"
                      to={{
                        name: 'create-account',
                        params: {
                          platform: location.params?.platform,
                        },
                      }}
                      id="createAccount"
                    />
                  ),
                }}
              />
            )}
          </PanelContent>
        </FormProvider>
      </Panel>
    </PanelWrapper>
  );
}

export default LoginViewComponent;
