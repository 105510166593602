import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import * as ApiErrors from '@tvoli/js-api-client/src/errors';
import validate from 'utils/validate';
import * as auth from 'actions/auth';
import * as user from 'actions/user';
import {
  FormattedError,
  ErrorMessage,
  Text,
  Box,
  Heading,
  Metadata,
} from 'components';
import { I18nContext } from 'components/I18n';
import { ClickTracker } from 'components/Tracking';
import PrimaryButton from 'components/Button/PrimaryButton';
import {
  FormField,
  TextField,
  Checkbox,
} from 'components/Forms';
import SettingsContainer from '../SettingsContainer';
import {
  AccountSettingsForm,
  ChangePasswordLinkButton,
} from './AccountStyles';

const SAVE_CONFIRMED_DISPLAY_TIME = 3000;

function AccountView() {
  const {
    contactEmail,
    loginEmail,
    initialValues,
    isContactEmailEnabled,
    isContactEmailAsDisplayedEmail,
    isEditable,
    isNewsletterSubscriptionEnabled,
  } = useSelector((state) => {
    /* eslint-disable @typescript-eslint/no-shadow */
    const {
      user: {
        contactEmail,
        emailOptIn,
        openIds,
      },

      settings: {
        features,
      },
    } = state;

    const loginType = Object.keys(openIds)[0];
    const loginEmail = openIds[loginType];

    return {
      contactEmail,
      emailOptIn,
      loginEmail,
      initialValues: {
        contactEmail,
        emailOptIn,
      },
      isContactEmailEnabled: !!features.settings.contactEmail,
      isContactEmailAsDisplayedEmail: features.settings.contactEmail?.isShownAsPrimary || false,
      isEditable: features.settings.contactEmail?.isEditable,

      // FIXME disabled due to MDM-3512, re-enable this when backend works correctly
      // isNewsletterSubscriptionEnabled: !!state.settings.features.newsletter,
      isNewsletterSubscriptionEnabled: false,
    };
    /* eslint-enable @typescript-eslint/no-shadow */
  });
  const i18n = useContext(I18nContext);
  const dispatch = useDispatch();
  const timer = useRef(null);
  const [passwordChangeRequestErrorMessage, setPasswordChangeRequestErrorMessage] = useState('');
  const [passwordChangeRequestPending, setPasswordChangeRequestPending] = useState(false);
  const [passwordChangeRequestSent, setPasswordChangeRequestSent] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const { handleSubmit, setError } = methods;

  useEffect(() => () => {
    clearTimeout(timer.current);
  }, []);

  const initializePasswordChange = async () => {
    setPasswordChangeRequestErrorMessage('');
    setPasswordChangeRequestPending(true);

    const response = await dispatch(auth.requestPasswordChange({
      email: contactEmail,
    })).catch(() => null);
    if (!response) {
      const msg = i18n.formatText('accountSettings.resetPasswordFail500');
      setPasswordChangeRequestErrorMessage(msg);
      setPasswordChangeRequestPending(false);
      return;
    }

    if (!response.ok) {
      setPasswordChangeRequestErrorMessage(i18n.formatText('noAccount', { email: contactEmail }));
      setPasswordChangeRequestPending(false);
      return;
    }

    setPasswordChangeRequestPending(false);
    setPasswordChangeRequestSent(true);
  };

  const submit = async (fields) => {
    try {
      await dispatch(user.updateSettings({
        contactEmail: fields.contactEmail,
        emailOptIn: fields.emailOptIn,
      }));
      await dispatch(user.getUser());
      setShowSaveConfirmation(true);

      // Hide confirmation after a few seconds
      timer.current = setTimeout(() => {
        setShowSaveConfirmation(false);
      }, SAVE_CONFIRMED_DISPLAY_TIME);
    } catch (err) {
      let msg = i18n.formatText('couldntSave');
      if (err instanceof ApiErrors.InvalidInputError) {
        if (err.fields && err.fields.email) {
          const reasonId = `formError.email.${err.fields.email}`;
          msg = i18n.formatText(
            'couldntSaveBecause',
            {
              reason: i18n.hasMessage(reasonId)
                ? i18n.formatText(`formError.email.${err.fields.email}`)
                : err.fields.email,
            },
          );
        }
        setError('contactEmail', {
          type: 'server',
          message: msg, // TODO i18n this
        });
        return;
      }

      setGeneralError(msg);
    }
  };

  return (
    <SettingsContainer>
      <Metadata title="account" />

      <Heading
        id="account"
        letterSpacing="2.3px"
        mb="small"
      />

      <Box mb="small">
        <Text
          id="email"
          color="secondary"
          fontSize="fine"
        />
      </Box>

      <Box mb="small">
        <Text color="secondary">
          {isContactEmailAsDisplayedEmail ? contactEmail : loginEmail}
        </Text>
      </Box>

      {isContactEmailEnabled && (
        <Box mt="xlarge">
          <FormProvider {...methods}>
            <AccountSettingsForm onSubmit={handleSubmit(submit)} method="POST">
              <Box mb="xlarge">
                {
                isEditable ? (
                  <FormField
                    as={TextField}
                    name="contactEmail"
                    placeholder={i18n.formatText('accountSettings.contactEmailLabel')}
                    type="email"
                    validations={{
                      email: validate.email(),
                    }}
                  />
                ) : (
                  <>
                    <Box mb="small">
                      <Text
                        id="accountSettings.contactEmailLabel"
                        color="secondary"
                        fontSize="fine"
                      />
                    </Box>
                    <Box mb="medium">
                      <Text>{contactEmail}</Text>
                    </Box>
                  </>
                )
              }
                {passwordChangeRequestSent === false && (
                  <Box mb="medium">
                    <ClickTracker
                      component="Settings"
                      element="reset_password_link"
                      clickType="action"
                    >
                      <ChangePasswordLinkButton
                        link
                        onClick={initializePasswordChange}
                        pending={passwordChangeRequestPending}
                      >
                        <Text id="accountSettings.resetPasswordLink" />
                      </ChangePasswordLinkButton>
                    </ClickTracker>
                  </Box>
                )}

                {passwordChangeRequestErrorMessage.length > 0 && (
                  <Box mb="medium">
                    <ErrorMessage>
                      {passwordChangeRequestErrorMessage}
                    </ErrorMessage>
                  </Box>
                )}

                {passwordChangeRequestSent && (
                  <Box mb="medium">
                    <Text
                      id="accountSettings.resetPasswordSuccessFeedback"
                      values={{
                        email: contactEmail,
                      }}
                      fontSize="small"
                    />
                  </Box>
                )}
              </Box>

              {isNewsletterSubscriptionEnabled && (
                <Box mb="medium">
                  <FormField
                    as={Checkbox}
                    name="emailOptIn"
                    label={{ id: 'accountSettings.newsletterSubscriptionLabel' }}
                  />
                </Box>
              )}

              <FormattedError error={generalError} />
              {
                isEditable || isNewsletterSubscriptionEnabled ? (
                  <Box mt="fine">
                    <PrimaryButton
                      variant="brand"
                      disabled={showSaveConfirmation}
                      type="submit"
                    >
                      <Text id={showSaveConfirmation ? 'accountSettings.saveSuccessFeedback' : 'save'} />
                    </PrimaryButton>
                  </Box>
                ) : null
              }
            </AccountSettingsForm>
          </FormProvider>
        </Box>
      )}
    </SettingsContainer>
  );
}

export default React.memo(AccountView);
