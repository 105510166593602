import React from 'react';
import PropTypes from 'prop-types';
import { viewableShape } from 'types/viewableShape';
import { formatHHmm, formatTimeLeft } from 'utils/human-format';
import { extractLiveEventTime } from 'utils/live-event';
import { getUpcomingBroadcastTime } from 'utils/broadcast';
import { Box, LiveLabel, Text, Spacer } from 'components';
import { UpcomingTitle, TITLE_TYPES } from 'components/Upcoming';
import MetaCountdown from 'components/Countdown/MetaCountdown';
import { DynamicClockIcon } from 'components/Icons';

const countDownTimer = (timer, startTime, timeLeftMs) => {
  if (!timer) return null;

  const { days } = timer;

  return (
    <>
      <LiveLabel // is here because need to be hidden if live
        big
        liveEventTime={{ startTime: startTime / 1000 }}
      />

      <Spacer width="medium" />

      {days > 0 ? (
        <UpcomingTitle
          type={TITLE_TYPES.HERO}
          startTime={startTime}
          fontSize="large"
          textShadow="normal"
          italic={false}
          bold
        />
      ) : (
        <Text
          bold
          fontSize="large"
          textShadow="normal"
          id="upcoming.comingIn"
          values={{ startTime: formatTimeLeft(timeLeftMs / 1000) }}
        />
      )}
    </>
  );
};

const liveEventCountDown = (timer, startTime, timeLeftMs) => {
  if (!timer) return null;

  const { today } = timer;

  return (
    <>
      <DynamicClockIcon
        currentTime={startTime / 1000}
        size="1.85rem"
        opacity="0.7"
        data-cy="dynamic-clock-icon"
      />
      <Spacer width="3px" />
      {today ? (
        <Text
          bold
          fontSize="large"
          textShadow="normal"
        >
          {formatTimeLeft(timeLeftMs / 1000)}
        </Text>
      ) : (
        <Text
          bold
          fontSize="large"
          textShadow="normal"
          id="liveLabel.starting"
          values={{ time: formatHHmm(startTime) }}
        />
      )}
    </>
  );
};

function EventCounter({ viewable, refreshData = () => {} }) {
  const liveEventTime = extractLiveEventTime(viewable);
  const broadcastStartTime = getUpcomingBroadcastTime(viewable?.defaultPlayable);

  return (
    <>
      {broadcastStartTime && !liveEventTime && ( // Broadcast label and counter
        <Box row mb="medium" alignItems="center" justifyContent="start">
          <MetaCountdown
            eventTimeMs={broadcastStartTime}
            onEvent={refreshData}
          >
            {countDownTimer}
          </MetaCountdown>
        </Box>
      )}

      {liveEventTime && ( // Live event label and counter
        <Box row mb="medium" alignItems="center" justifyContent="start">
          <LiveLabel
            big
            liveEventTime={liveEventTime}
          />
          <Spacer width="medium" />
          <MetaCountdown
            eventTimeMs={liveEventTime.startTime * 1000}
            onEvent={refreshData}
          >
            {liveEventCountDown}
          </MetaCountdown>
        </Box>
      )}
    </>
  );
}

EventCounter.propTypes = {
  viewable: viewableShape.isRequired,
  refreshData: PropTypes.func,
};

export default React.memo(EventCounter);
