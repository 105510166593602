import { useMemo, useState, useCallback } from 'react';
import * as Apollo from '@apollo/client';
import {
  useViewableQuery,
  useViewableLazyQuery,
  TViewableQuery,
  TViewableQueryVariables,
  ViewableQueryHookResult,
} from './viewable.generated';
import { ViewableType } from 'types/types';
import { normalizeWatchViewViewable } from './normalizeViewable';

/*
* @example
* const { data, loading, error } = useViewable({
  *   variables: {
    *      viewableId: string
    *      broadcastId: string
    *   },
  * });
*/

type NormalizedResult = {
  data: ViewableType
};

type ViewableResult = Omit<ViewableQueryHookResult, 'data'> & NormalizedResult;

export default function useViewable(
  options: Apollo.QueryHookOptions<TViewableQuery, TViewableQueryVariables>,
): ViewableResult {
  const [counter, setCounter] = useState(0);
  const { data, refetch, ...res } = useViewableQuery({
    skip: !options.variables?.viewableId,
    errorPolicy: 'ignore',
    ...options,
  });

  const viewable = useMemo(
    () => {
      if (data?.viewer?.viewable) {
        return normalizeWatchViewViewable(data.viewer.viewable, options.variables?.broadcastId);
      }
      return undefined;
    },
    [data?.viewer?.viewable, counter],
  );

  // refresh viewable after refetch
  const refetchHandler = useCallback(() => refetch && refetch()?.then((result) => {
    if (result.data === data) setCounter(counter + 1);
    return result;
  }), [refetch, counter]);

  return {
    data: viewable,
    refetch: refetchHandler,
    ...res,
  };
}

export function useViewableLazy(
  options: Apollo.QueryHookOptions<TViewableQuery, TViewableQueryVariables>,
): [() => void, ViewableResult] {
  const [loadViewable, { data, ...res }] = useViewableLazyQuery({
    skip: !options.variables?.viewableId,
    errorPolicy: 'ignore',
    ...options,
  });

  const viewable = useMemo(
    () => {
      if (data?.viewer?.viewable) {
        return normalizeWatchViewViewable(data.viewer?.viewable, options.variables?.broadcastId);
      }
      return undefined;
    },
    [data?.viewer?.viewable],
  );

  return [loadViewable, {
    data: viewable,
    ...res,
  } as ViewableResult];
}
