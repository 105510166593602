import React from 'react';
import PropTypes from 'prop-types';
import { VIEWABLE_TYPES } from 'utils/constants';
import { extractLiveEventTime } from 'utils/live-event';
import { extractUpcomingStartTime } from 'utils/upcoming';
import { getChannelLogoData } from 'utils/channel';
import { getLinkParams, getPlayableProp } from 'utils/helpers';
import {
  Image,
  Box,
  LiveLabel,
  LiveInfo,
  VideoProgressBar,
} from 'components';
import ChannelLogo from 'components/ChannelLogo/ChannelLogo';
import FixedChannelLogo from 'components/ChannelLogo/FixedChannelLogo';
import { UpcomingTitle, TITLE_TYPES } from 'components/Upcoming';
import { viewableShape } from 'components/VideoItems/commonPropTypes';
import { ContainerLink } from 'components/VideoItems/Styles';

const DEFAULT_LOGO_OFFSET = 1;
const CHANNEL_SIZE = 5.625; // rem

const positionRelated = {
  position: 'relative',
};

function PosterItem(props) {
  const {
    viewable,
    disableLiveInfo,
    asLink,
    thumbnailSizeFactor,
    hideChannelLogo,
  } = props;

  const liveEventTime = extractLiveEventTime(viewable);
  const channel = getChannelLogoData(viewable);
  const isChannel = viewable.__typename === VIEWABLE_TYPES.Channel;
  const watchOffset = getPlayableProp(viewable, 'watchOffset');
  const upcomingTimestamp = extractUpcomingStartTime(viewable);

  // if (viewable.__typename === VIEWABLE_TYPES.Show) debugger

  const handleItemClick = () => {
    const {
      id,
      playableId,
    } = getLinkParams(viewable);

    if (props.onClick) {
      props.onClick(id, playableId);
    }
  };

  const poster = isChannel ? (
    <ChannelLogo
      alt={viewable.title}
      src={viewable.logoDark}
      withBackground
      size={`${CHANNEL_SIZE * thumbnailSizeFactor}rem`}
    />
  ) : (
    <Image
      alt={viewable.title}
      hasShadow
      src={viewable.poster || viewable.banner}
      type="poster"
      rounded
      sizeFactor={thumbnailSizeFactor}
    />
  );

  const content = (
    <>
      <Box position="relative" mb={upcomingTimestamp ? 'fine' : undefined}>
        {liveEventTime && (
          <Box
            position="absolute"
            top="0"
            right="0.8rem"
            zIndex={2}
          >
            <LiveLabel liveEventTime={liveEventTime} />
          </Box>
        )}
        {poster}
        {!viewable.entitlement || watchOffset > 0 ? (
          <Box
            column
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            alignItems="flex-start"
          >
            {watchOffset > 0 && (
              <VideoProgressBar
                offset={watchOffset}
                duration={getPlayableProp(viewable, 'duration')}
                extend={positionRelated}
              />
            )}
          </Box>
        ) : null}
        {!hideChannelLogo && !isChannel && channel && (
          <FixedChannelLogo
            alt={channel.title}
            src={channel.logoDark}
            withBackground
            size="45%"
            offset={`${DEFAULT_LOGO_OFFSET * thumbnailSizeFactor}em`}
          />
        )}
      </Box>
      {upcomingTimestamp ? (
        <Box textAlign="center">
          <UpcomingTitle
            type={TITLE_TYPES.POSTER}
            opacity={0.7}
            startTime={upcomingTimestamp}
          />
        </Box>
      ) : null}
      {liveEventTime && !disableLiveInfo && (
        <Box mt="fine">
          <LiveInfo liveEventTime={liveEventTime} onlyToday />
        </Box>
      )}
    </>
  );

  if (asLink) {
    return (
      <ContainerLink
        to={{
          name: 'watch',
          params: getLinkParams(viewable),
        }}
        onClick={handleItemClick}
        className="e2e-asset"
      >
        {content}
      </ContainerLink>
    );
  }

  return (
    <Box
      relative
      className="e2e-asset"
      onClick={handleItemClick}
    >
      {content}
    </Box>
  );
}

PosterItem.propTypes = {
  viewable: viewableShape.isRequired,
  onClick: PropTypes.func,
  disableLiveInfo: PropTypes.bool,
  asLink: PropTypes.bool,
  thumbnailSizeFactor: PropTypes.number,
  hideChannelLogo: PropTypes.bool,
};

PosterItem.defaultProps = {
  asLink: true,
};

export default React.memo(PosterItem);
