import createComponent from 'styles/fela/createComponent';
import { responsiveLg, withBasePadding } from 'styles/fela/mixins';

export const Wrapper = createComponent(({ theme }) => ({
  margin: `${theme.margin.fine} auto`,
  paddingBottom: theme.margin.large,
  display: 'flex',
  flexFlow: 'wrap',
  gap: '2.75vw 3.125vw ',
  justifyContent: 'space-between',
  '::after': {
    content: '""',
    flex: '1 1 50px',
    visibility: 'hidden',
  },
  extend: [
    withBasePadding(),
    responsiveLg({
      gap: '30px 40px',
    }),
  ],
}));

export const ChannelButton = createComponent(({ theme, isFavorite, disabled }) => ({
  backgroundColor: theme.color.overlayDarkFaint,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  border: '2px solid lightgray',
  borderRadius: '50%',
  cursor: 'pointer',
  height: '6vw',
  maxHeight: '72px',
  maxWidth: '72px',
  minHeight: '6px',
  minWidth: '36px',
  outline: 'none',
  padding: 0,
  width: '6vw',
  extend: [
    {
      condition: isFavorite,
      style: {
        backgroundColor: theme.color.highlight,
        borderColor: theme.color.brand,
      },
    },
    {
      condition: disabled,
      style: {
        filter: 'grayscale(100%)',
      },
    },
  ],
}), 'button', ['isFavorite', 'disabled']);

ChannelButton.displayName = 'ChannelButton';
