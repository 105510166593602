import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';
import { findById } from 'utils/helpers';
import * as packsActions from 'actions/packs';
import * as billingActions from 'actions/billing';
import { addMessage } from 'actions/messages';
import logger from 'utils/logger';
import {
  ADYEN_RESULT_TYPES,
  EntitlementStatus, PAYMENT_PROVIDERS,
} from 'utils/constants';
import {
  useSubmitAdyenCheckoutPaymentDetailsMutation,
} from 'components/AdyenDropIn/submitAdyenCheckoutPaymentDetails.generated';
import { ERROR_RESPONSES } from 'views/Checkout/ReceiptView';

function findDiscountById(discounts, offerId) {
  return discounts.find(discount => discount.discount.pack === offerId);
}

const DEFAULT_POLL_TIME = 2 * 1000; // 2 sec
const MAX_POLL_TIME = 10 * 1000; // 10 sec

const EntitledOfferLoader = (props) => {
  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const store = useStore();
  const {
    isAdyenDropin,
  } = useSelector(({ settings }) => ({
    isAdyenDropin: settings.features.payment.provider.type === PAYMENT_PROVIDERS.ADYEN_DROPIN,
  }), shallowEqual);

  const onSuccess = async (pack, discount) => {
    try {
      await dispatch(billingActions.getPayments());

      const payments = store.getState().billing.transactions;

      // find current transaction in list of all transactions
      const transaction = payments
        .filter(
          payment => payment.productIds.includes(pack.id) && payment.status === 'success',
        )
        .sort((a, b) => a.timestamp - b.timestamp)
        .pop();

      props.onSuccess(pack, discount, transaction);
    } catch (e) {
      dispatch(addMessage({ contentId: 'failedMessage' }));
      logger.error('Failed to load transactions', e);
      props.onFailure(e);
    }
  };

  /**
   * Retry fetching entitled packs until the user has expected pack.
   *
   * This function is used during signup to ensure that the activation of
   * the pack is finalized on the backend side, before giving feedback to
   * the user.
   */
  const fetchEntitledOfferWithRetry = async () => {
    const {
      offerId,
      onTimeout,
    } = props;

    let isTimedOut = false;
    let pollTime = DEFAULT_POLL_TIME;
    let results = await dispatch(packsActions.fetchEntitledPacks());

    // Stop trying after some time
    const giveUpDelay = setTimeout(() => {
      isTimedOut = true;
    }, 5 * 60 * 1000); // timeout in 5 min

    while (mountedRef.current) {
      const pack = findById(results.packs, offerId);
      const discount = findDiscountById(results.discounts, offerId);

      if (pack?.status === EntitlementStatus.active) {
        clearTimeout(giveUpDelay);
        onSuccess(pack, discount);
        return;
      }

      if (isTimedOut) {
        onTimeout();
        break;
      }

      // We do actually want to repeat the fetch with waiting times in between
      // eslint-disable-next-line
      await new Promise(resolve => setTimeout(resolve, pollTime));

      if (pollTime < MAX_POLL_TIME) {
        pollTime *= 2;
      }

      // eslint-disable-next-line no-await-in-loop
      results = await dispatch(packsActions.fetchEntitledPacks());
    }
  };

  const [
    getAdyenPaymentDetails,
  ] = useSubmitAdyenCheckoutPaymentDetailsMutation({
    onCompleted: (data) => {
      const { onFailure, onRefusal } = props;
      try {
        const adyenResponse = JSON.parse(data.result.adyenResponse);
        const resultCode = adyenResponse.resultCode.toUpperCase();
        // check authentication status for 3DS
        if (resultCode === ADYEN_RESULT_TYPES.AUTHORISED) {
          fetchEntitledOfferWithRetry().catch(onFailure);
        } else if (ERROR_RESPONSES.includes(adyenResponse.resultCode.toUpperCase())) {
          onRefusal(adyenResponse.refusalReason);
        } else {
          onFailure(adyenResponse.refusalReason);
        }
      } catch (e) {
        onFailure(e);
      }
    },
    onError: props.onFailure,
  });

  useEffect(() => {
    const {
      redirectResult,
      transactionId,
      onFailure,
    } = props;

    const continueTransaction = async () => {
      try {
        if (redirectResult && isAdyenDropin) {
          // call get payment details for 3DS to check authentication status
          await getAdyenPaymentDetails({
            variables: {
              input: {
                transactionId,
                details: JSON.stringify({
                  redirectResult,
                }),
              },
            },
          });
        }
        await fetchEntitledOfferWithRetry();
      } catch (e) {
        onFailure(e);
      }
    };

    continueTransaction();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return null;
};

EntitledOfferLoader.propTypes = {
  offerId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onTimeout: PropTypes.func.isRequired,
  onRefusal: PropTypes.func.isRequired,
  redirectResult: PropTypes.string,
  transactionId: PropTypes.string,
};

export default React.memo(EntitledOfferLoader);
