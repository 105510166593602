import createComponent from 'styles/fela/createComponent';
import ButtonWithSpinner from './ButtonWithSpinner';

const BasicButton = createComponent(({
  theme,
  minWidth,
  size,
  fixedWidth,
  withWidth,
  fullWidth,
  disabled,
  nowrap,
  lineHeight,
}) => ({
  border: '0 none',
  borderRadius: theme.radius.default,
  textAlign: 'center',
  outline: 'none',
  userSelect: 'none',

  cursor: 'pointer',
  display: 'block',
  width: 'auto',
  minWidth: theme.size.fixedWidthButton,
  height: '3.5rem',
  lineHeight: '3.3rem',
  alignContent: 'center',
  paddingLeft: theme.margin.large,
  paddingRight: theme.margin.large,
  textDecoration: 'none',

  '&[disabled]': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.color.disabled,
  },

  extend: [
    {
      condition: minWidth,
      style: {
        minWidth,
      },
    },
    {
      condition: size === 'medium',
      style: {
        height: '2rem',
        lineHeight: '2rem',
        textTransform: 'capitalize',
      },
    },
    {
      condition: withWidth || fixedWidth,
      style: {
        minWidth: 'auto',
        width: withWidth || theme.size.fixedWidthButton,
      },
    },
    {
      condition: fullWidth,
      style: {
        width: '100%',
      },
    },
    {
      condition: disabled,
      style: {
        cursor: 'default',
      },
    },
    {
      condition: nowrap,
      style: {
        whiteSpace: 'nowrap',
      },
    },
    {
      condition: lineHeight,
      style: {
        lineHeight,
      },
    },
  ],
}), ButtonWithSpinner, ['minWidth', 'size', 'fixedWidth', 'withWidth', 'fullWidth', 'nowrap', 'lineHeight']);

export default BasicButton;
