import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton, Text } from 'components';
import { push } from 'router/actions';
import { useDispatch } from 'react-redux';

function EpisodeButton({ viewableId }) {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(push({
      name: 'watch',
      params: {
        id: viewableId,
      },
    }));
  };

  return (
    <SecondaryButton
      variant="white"
      onClick={onClick}
      minWidth="auto"
      fullWidth
      sm-fullWidth={false}
      data-cy="episode-button"
    >
      <Text id="seeAllEpisodes" />
    </SecondaryButton>
  );
}

EpisodeButton.propTypes = {
  viewableId: PropTypes.string,
};
export default React.memo(EpisodeButton);
