import createComponent from 'styles/fela/createComponent';
import {
  withSpinning,
} from 'styles/fela/mixins';
import { BUTTON_HEIGHT_BIG } from 'utils/constants';
import CircleIcon from 'components/Icons/CircleIcon';
import TrailerIcon from './TrailerIcon';

export const Container = createComponent(({ theme, inProgress }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontSize: theme.fontSize.normal,
  paddingRight: theme.margin.small, // fixes tooltip wrong position
  letterSpacing: '0.5px',
  userSelect: 'none',
  extend: [
    {
      condition: inProgress,
      style: {
        cursor: 'wait',
      },
    },
  ],
  '& svg circle': {
    transition: 'fill 0.2s ease-in-out',
  },
  ':hover:not([disabled])  svg circle': {
    fill: theme.color.whiteButtonBackgroundHover,
  },
}), 'div', ['inProgress']);

export const IconContainer = createComponent(() => ({
  position: 'relative',
  width: BUTTON_HEIGHT_BIG,
  height: BUTTON_HEIGHT_BIG,
  overflow: 'hidden',
}));

export const StyledCircleIcon = createComponent(({ theme }) => ({
  color: 'transparent',
  width: '100%',
  height: '100%',
  overflow: 'visible',
  fill: theme.color.whiteButtonBackground,
}), CircleIcon, []);

export const StyledTrailerIcon = createComponent(({ inProgress }) => ({
  color: 'white',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'visible',
  extend: [
    {
      condition: inProgress,
      style: withSpinning(1000, true, 1),
    },
  ],
}), TrailerIcon, ['inProgress']);
