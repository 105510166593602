import queryString from 'query-string';
import { transparentize } from 'polished';
import mergeWith from 'lodash.mergewith';
import * as storageList from 'utils/storage';
import { v4 as uuidv4 } from 'uuid';
import { BREAKPOINTS, OFFER_TYPES, RECURRING_PERIOD, VIEWABLE_TYPES } from 'utils/constants';
import { formatClockTime, formatDuration } from 'utils/human-format';

// interpret elem as object and convert it to string like "[object Undefined]"
// and extract information about elem type
export function getType(elem) {
  return Object.prototype.toString.call(elem).slice(8, -1);
}

const isType = t => elem => getType(elem) === t;

export const isObject = isType('Object');
export const isArray = isType('Array');
export const isFunction = isType('Function');

export function imageWithSize(url, pxWidth, pxHeight) {
  if (!url) {
    return undefined;
  }

  if (!pxWidth && !pxHeight) {
    return url;
  }

  const q = queryString.stringify({
    width: pxWidth ? Math.round(pxWidth) : undefined,
    height: pxHeight ? Math.round(pxHeight) : undefined,
  });

  return `${url}?${q}`;
}

export function rem2px(rem, base = 16) {
  return (rem || 0) * base;
}

export function responsiveRem2Px(rem) {
  const fontSize = __CLIENT__ && window.innerWidth > BREAKPOINTS.lg ? 16 : 14;
  return rem2px(rem, fontSize);
}

export function onlyDigits(str) {
  return str.replace(/\D/g, '');
}

export function acceptDigits(value) {
  return onlyDigits(value || '');
}

/**
 * Split array into subarrays. New subarray starts when fn returns true.
 * @param {Function(item, pos)} fn function which decides if new subarray should start
 * @param {Array} array array to split
 * @returns {Array} new array of subarrays
 */
export function partitionBy(fn, array = []) {
  const result = [];

  if (!array.length) {
    return result;
  }

  let current = [];
  array.forEach((item, pos) => {
    if (fn(item, pos)) {
      if (current.length) {
        result.push(current);
      }
      current = [];
    }

    current.push(item);
  });

  if (current.length) {
    result.push(current);
  }

  return result;
}

export function partitionString(str, groupSize) {
  return partitionBy(
    // split value into chars and group them into arrays based on groupSize
    (char, pos) => pos % groupSize === 0, str.split(''),
  ).map(
    group => group.join(''), // convert grouped chars back into strings
  );
}

export function range(start, end) {
  return Array(end - start).fill(0).map((zero, i) => zero + start + i);
}

export function uniq(arr, getId = item => item) {
  const ids = new Set();
  const result = [];

  arr.forEach((item) => {
    const id = getId(item);
    if (ids.has(id)) {
      return;
    }

    ids.add(id);

    result.push(item);
  });

  return result;
}

export const RequestStates = {
  initial: 'initial',
  pending: 'pending',
  success: 'success',
  failed: 'failed',
};

export const logoDarkOrLight = (channelObj, theme) => {
  if (theme && theme.theme === 'dark') {
    return channelObj.logoLight;
  }
  return channelObj.logoDark;
};

export const logoDarkOrLightUrl = (channelObj, theme) => {
  const logo = logoDarkOrLight(channelObj, theme);
  if (logo) {
    return `url(${logo})`;
  }

  return null;
};

export const flattenEdges = item => item?.edges?.filter(edge => edge).map(edge => edge.node) || [];

export const findById = (arr, id) => arr.find(item => item.id === id);

export const arraysAreEqual = (array1, array2) => {
  if (!array1 || !array2) {
    return false;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0, l = array1.length; i < l; i += 1) {
    // Check if we have nested arrays
    if (array1[i] instanceof Array && array2[i] instanceof array2) {
      // recurse into the nested arrays
      if (!arraysAreEqual(array1[i], array2[i])) {
        return false;
      }
    } else if (array1[i] !== array2[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};

export function utf8ToLatin1(str) {
  // eslint-disable-next-line prefer-template
  return str.replace(/[\u00A0-\uffff]/gu, c => '\\u' + ('000' + c.charCodeAt().toString(16)).slice(-4));
}

// based on http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
export function hashCode(str) {
  let hash = 0;

  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + str.charCodeAt(i);

    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }

  // avoid negative values
  return hash < 0 ? `0${hash * -1}` : `1${hash}`;
}

export function replaceWebviewParam(url, param, value) {
  if (!value) {
    return url;
  }

  return url.replace(new RegExp(`\\[${param}\\]`, 'gi'), value);
}

/**
 * Returns a promise that resolves when a certain number of milliseconds
 * passes.
 *
 * @param {Number} n Milliseconds until promise resolves
 */
export const sleep = n => (new Promise((resolve) => {
  setTimeout(resolve, n);
}));

/**
 * Given a promise, it will retry running it before finally giving up. To
 * specify a failure the promise should throw an error. If the error is still
 * thrown after the number of retries or timeout expires, then it will be
 * re-thrown.
 *
 * Function stops retrying whenever one of two conditions is met: either number
 * of retries reached, or timeout period expires.
 *
 * @param {Function} promise The promise to be run
 * @param {Number} numRetries The maximum number of retries
 * @param {Number} timeout The maximum wait interval before giving up, in ms
 * @param {Number} interval The wait interval between retries, in ms
 */
export const retry = async (
  promise,
  numRetries = 30,
  timeout = 5000,
  interval = 500,
) => {
  let i = numRetries;
  let isTimedOut = false;

  // Stop trying after some time
  const giveUpDelay = setTimeout(() => {
    isTimedOut = true;
  }, timeout);

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await promise();
      return result;
    } catch (error) {
      if (i <= 0) {
        clearTimeout(giveUpDelay);
        throw Error(`retry() Max number of attempts (${numRetries}) was exceeded. ${error}`);
      }

      if (isTimedOut) {
        clearTimeout(giveUpDelay);
        throw Error(`retry() Timeout of ${timeout} ms was reached. ${error}`);
      }

      if (interval > 0) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(interval);
        i -= 1;
      }
    }
  }
};

/**
 * @param {string} color
 * @param {number} opacity 0 to 100
 */
export function colorWithOpacity(color, opacity) {
  return transparentize(1 - opacity / 100, color);
}

export function withSeparator(array, separator) {
  const result = [];

  for (let i = 0; i < array.length; i += 1) {
    const item = array[i];

    if (result.length) {
      result.push(isFunction(separator) ? separator(item, i) : separator);
    }

    result.push(item);
  }

  return result;
}

export function getRandomIdFromStorage(storage, key) {
  try {
    let savedId = storage.getItem(key);

    if (!savedId) {
      savedId = uuidv4();
      storage.setItem(key, savedId);
    }

    return savedId;
  } catch (err) {
    return uuidv4();
  }
}

export const getSessionRandomId = getRandomIdFromStorage.bind(this, storageList.sessionStorage);
export const getPersistentRandomId = getRandomIdFromStorage.bind(this, storageList.localStorage);

export function getGoogleFontLink(fontName) {
  return `https://fonts.googleapis.com/css?family=${fontName.replace(/ /g, '+')}`;
}

export function mergeConfigs(...configs) {
  return mergeWith(
    {},
    ...configs,

    // do not override array - always use new value
    (objValue, srcValue) => (Array.isArray(objValue) ? srcValue : undefined),
  );
}

export const getBaseFontFamily = theme => theme.fontFamily.split(',')
  .map(ff => ff.trim())
  .filter(ff => !theme.fontFace.some(({ fontFamily }) => ff === fontFamily))
  .join(', ');

export const addResizerParams = ({ src, breakpoint = 'xl', crop, ratio = 16 / 9, minWidth, minHeight } = {}) => {
  if (!src) return '';
  if (src.includes('width=')) return src;

  let width = BREAKPOINTS[BREAKPOINTS.hasOwnProperty(breakpoint) ? breakpoint : 'xl'];
  if (minWidth && width < minWidth) {
    width = minWidth;
  }

  let height = Math.round(width / ratio);
  if (minHeight && height < minHeight) {
    height = minHeight;
    width = Math.round(height * ratio);
  }

  return `${src}?height=${height}&width=${width}${crop ? '' : '&op=clip'}`;
};

export const isTVOD = offerType => [
  OFFER_TYPES.BuyType,
  OFFER_TYPES.RentType,
  OFFER_TYPES.PassType,
].includes(offerType);

export const getRecurringPeriod = (offer) => {
  const unit = offer.recurringPeriod?.unit || '';
  const value = RECURRING_PERIOD[unit];

  if (unit && !value) {
    throw new Error(`Unsupported subscription period ${offer.recurringPeriod}`);
  }

  return unit ? value : undefined;
};

export function getLinkParams(viewable) {
  return {
    id: viewable.show?.id || viewable.id,
  };
}

export function renderDuration(viewable) {
  if (viewable.__typename === VIEWABLE_TYPES.Show) {
    return null;
  }

  if (viewable.durationHuman) {
    return viewable.durationHuman;
  }

  if (viewable.duration) {
    return formatDuration(viewable.duration);
  }

  if (viewable.start && viewable.stop) {
    return formatDuration(viewable.stop - viewable.start);
  }

  const broadcast = viewable.schedule?.[0];
  if (broadcast) {
    return formatDuration(broadcast.stop - broadcast.start);
  }

  return null;
}

export const generateWatchLocation = (viewable) => {
  if (viewable.show?.id) {
    // redirect to Show with appropriate Episode
    return {
      name: 'watch',
      params: {
        id: viewable.show?.id,
        playableId: viewable.defaultPlayable.id,
      },
    };
  }

  return {
    name: 'watch',
    params: { id: viewable.id },
  };
};

export const getStartDateTime = (time, i18n) => {
  if (!time) return null;

  const startDate = i18n.formatDate(time, { weekday: 'long', month: 'long', day: 'numeric' });
  const startTime = i18n.formatDate(time, { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });

  return `${startDate?.charAt(0).toUpperCase()}${startDate?.slice(1)} ${startTime}`;
};

export const getStartAndStopTime = (viewable) => {
  const start = viewable.broadcastById?.start || viewable.schedule?.[0]?.start;
  const stop = viewable.broadcastById?.stop || viewable.schedule?.[0]?.stop;

  if (start && stop) {
    return `${formatClockTime(start)} – ${formatClockTime(stop)}`;
  }

  return null;
};

export const getPlayableProp = (viewable, name, def = 0) => viewable.defaultPlayable?.[name]
  || viewable.selectedEpisode?.defaultPlayable?.[name]
  || viewable[name]
  || def;

export const getImageUrl = (images, isDefault, kind = 'sixteen_nine') => {
  return images?.find(img => img.kind === kind && img.isDefault === isDefault)?.url;
};
