import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import * as telemetryActions from 'actions/telemetry';
import { ANALYTICS_ACTIONS, ANALYTICS_EVENTS } from 'utils/constants';
import { getPersistentRandomId } from 'utils/helpers';
import Logger from 'utils/logger';
import Analytics from './Analytics';
import { COOKIE_TOOL_TYPES } from 'components/CookieTool/CookieTool';

export const AnalyticsContext = React.createContext();

const getTelemetryData = (data, props) => ({
  source: data.source,
  timestamp: Math.trunc(new Date().getTime() / 1000),
  context: {
    ...data.context,
    device_type: 'web',
    device_model: (props.browser.description || '').toLowerCase(),
    device_platform: (props.browser.name || '').toLowerCase(),
    device_id: getPersistentRandomId('deviceId'),
    browser_name: undefined,
    browser_version: undefined,
    seqId: undefined,
  },
  seqId: data.context.seqId,
});

const AnalyticsProvider = (props) => {
  const hasConstructorRun = useRef(false);
  const [analytics, setAnalytics] = useState();
  const resolveAnalyticsPromise = useRef();

  if (!hasConstructorRun.current) {
    const analyticsPromise = new Promise((resolve) => {
      resolveAnalyticsPromise.current = resolve;
    });

    setAnalytics(
      new Analytics({
        send(data) {
          const event = data.eventName || data.action;

          if (event === ANALYTICS_ACTIONS.PAGE_VIEW) {
            props.sendTelemetryEvents({
              ...getTelemetryData(data, props),
              action: event,
              component: props.pathname,
            }).catch(e => Logger.warn('Failed to send telemetry', e));
          }

          if (
            data.action === ANALYTICS_ACTIONS.CLICK
            || data.action === ANALYTICS_ACTIONS.VISIBLE
          ) {
            props.sendTelemetryEvents({
              ...getTelemetryData(data, props),
              action: data.action,
              component: data.component,
              click_type: data.clickType,
              viewable_id: data.viewableId,
              extra: data.extra,
              source: 'USER_ACTION',
            }).catch(e => Logger.warn('Failed to send telemetry', e));
          }

          if (data.action === ANALYTICS_ACTIONS.CANCEL) {
            props.sendTelemetryEvents({
              ...getTelemetryData(data, props),
              action: data.action,
              component: data.component,
              source: 'USER_ACTION',
            }).catch(e => Logger.warn('Failed to send telemetry', e));
          }

          if (!props.googleTagManagerId) {
            return;
          }

          analyticsPromise.then((dataLayer) => {
            dataLayer.push({
              event,
              ...data,
            });

            // https://developers.google.com/tag-manager/enhanced-ecommerce#purchases
            if (data.eventName === ANALYTICS_EVENTS.OFFER
              && data.action === ANALYTICS_ACTIONS.BUY
            ) {
              dataLayer.push({
                event: 'purchase',
                ecommerce: {
                  currencyCode: data.context.currency,
                  purchase: {
                    actionField: {
                      id: data.context.userId,
                      revenue: data.context.netPrice,
                      coupon: data.context.promoCode,
                      transactionId: data.context.transactionId,
                    },
                    products: [{
                      id: data.context.id,
                      name: data.context.name,
                      price: data.context.grossPrice,
                      quantity: 1,
                    }],
                  },
                },
              });
            }
          });
        },
        version: props.version,
        hash: props.hash,
        browser: props.browser,
      }),
    );
    hasConstructorRun.current = true;
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    resolveAnalyticsPromise.current(window.dataLayer);
  }, []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {props.googleTagManagerId && (
        <Helmet>
          <script {...props.cookieTool?.type === COOKIE_TOOL_TYPES.COOKIEBOT ? { 'data-cookieconsent': 'ignore' } : {}}>
            {`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${props.googleTagManagerId}');
              `}
          </script>
        </Helmet>
      )}

      {props.children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  version: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  googleTagManagerId: PropTypes.string,
  cookieTool: PropTypes.shape({
    type: PropTypes.string.isRequired,
    param: PropTypes.object,
    script: PropTypes.string,
  }),
  children: PropTypes.element.isRequired,
  sendTelemetryEvents: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  browser: PropTypes.object.isRequired,
};

const mapStateToProps = ({ settings, router, common }) => ({
  version: settings.app.version,
  pathname: router.pathname,
  hash: settings.app.hash,
  browser: {
    name: common.browser,
    version: common.version,
    description: common.description,
  },
  googleTagManagerId: settings.features.googleTagManager?.id || null,
  cookieTool: settings.cookieTool || null,
});

const mapDispatchToProps = {
  sendTelemetryEvents: telemetryActions.sendEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsProvider);
