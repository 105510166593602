import React from 'react';
import PropTypes from 'prop-types';
import { extractLiveEventTime } from 'utils/live-event';
import { getChannelLogoData } from 'utils/channel';
import { getPlayableProp } from 'utils/helpers';
import {
  Image,
  Box,
  VideoProgressBar,
  LiveLabel,
} from 'components';
import FixedChannelLogo from 'components/ChannelLogo/FixedChannelLogo';
import { viewableShape } from 'components/VideoItems/commonPropTypes';

const DEFAULT_LOGO_OFFSET = 1;

const positionRelated = {
  position: 'relative',
};

function SixteenNineImage(props) {
  const {
    viewable,
    withProgressBar,
    type,
    fullWidth,
    sizeFactor,
    hideChannelLogo,
  } = props;

  const channel = getChannelLogoData(viewable);
  const liveEventTime = extractLiveEventTime(viewable);
  const watchOffset = getPlayableProp(viewable, 'watchOffset');

  return (
    <Box position="relative">
      {liveEventTime && (
        <Box
          position="absolute"
          top="0"
          right="0.8rem"
          zIndex={2}
        >
          <LiveLabel liveEventTime={liveEventTime} />
        </Box>
      )}
      <Image
        alt={channel?.title || viewable.title}
        hasShadow
        withMarginBottom={false}
        src={viewable.banner}
        type={type}
        fullWidth={fullWidth}
        sizeFactor={sizeFactor}
        rounded
      />
      {!viewable.entitlement || watchOffset > 0 ? (
        <Box
          column
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          alignItems="flex-start"
        >
          {withProgressBar && watchOffset > 0 && (
            <VideoProgressBar
              offset={watchOffset}
              duration={getPlayableProp(viewable, 'duration')}
              extend={positionRelated}
            />
          )}
        </Box>
      ) : null}
      {!hideChannelLogo && channel && (
        <FixedChannelLogo
          alt={channel.title}
          src={channel.logoDark}
          withBackground
          offset={`${DEFAULT_LOGO_OFFSET * sizeFactor}em`}
        />
      )}
    </Box>
  );
}

SixteenNineImage.propTypes = {
  viewable: viewableShape.isRequired,
  type: PropTypes.oneOf([
    'sixteenNineBanner',
    'listSixteenNineBanner',
    'gridSixteenNineBanner',
    'smallBanner',
  ]),
  withProgressBar: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sizeFactor: PropTypes.number,
  hideChannelLogo: PropTypes.bool,
};

SixteenNineImage.defaultProps = {
  type: 'sixteenNineBanner',
  fullWidth: false,
  sizeFactor: 1,
};

export default React.memo(SixteenNineImage);
