import PropTypes from 'prop-types';
import {
  VIEWABLE_TYPES,
  PLAYABLE_KINDS,
  OFFER_TYPES,
} from 'utils/constants';

export const offerShape = PropTypes.shape({
  __typename: PropTypes.oneOf(Object.values(OFFER_TYPES)).isRequired,
  id: PropTypes.string.isRequired,
  priceInCents: PropTypes.number,
  currency: PropTypes.string,
  entitlementDurationSec: PropTypes.number,
});

const entitlementShape = PropTypes.shape({
  __typename: PropTypes.string.isRequired,
  offer: offerShape,
  purchasedAt: PropTypes.string, // ISO-8601
  entitledUntil: PropTypes.string, // ISO-8601
});

const playableShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  duration: PropTypes.number,
  watchOffset: PropTypes.number,
  catchup: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }),
  channel: PropTypes.shape({
    title: PropTypes.string,
    logoDark: PropTypes.string,
  }),
  kind: PropTypes.oneOf(Object.values(PLAYABLE_KINDS)).isRequired,
  startTimeUtc: PropTypes.number,
  mms: PropTypes.bool,
  mmsOrigCode: PropTypes.string,
});

const viewableType = PropTypes.oneOf([
  VIEWABLE_TYPES.Movie,
  VIEWABLE_TYPES.Episode,
  VIEWABLE_TYPES.Show,
  VIEWABLE_TYPES.Channel,
  VIEWABLE_TYPES.Program,
  VIEWABLE_TYPES.Trailer,
]);

const metaShape = PropTypes.shape({
  __typename: PropTypes.string.isRequired,
});

export const relatedViewableShape = PropTypes.shape({
  __typename: viewableType.isRequired,
  id: PropTypes.string.isRequired,
  poster: PropTypes.string,
  title: PropTypes.string,
  logoDark: PropTypes.string,
  defaultPlayable: PropTypes.shape({
    id: PropTypes.string,
  }),
  offers: PropTypes.arrayOf(metaShape),
  entitlement: metaShape,
});

export const webviewShape = PropTypes.shape({
  webviewUrl: PropTypes.string.isRequired,
  webviewTitle: PropTypes.string.isRequired,
  default: PropTypes.bool,
  webviewHeight: PropTypes.string,
});

export const viewableTagShape = PropTypes.shape({
  translationKey: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchable: PropTypes.bool,
});

export const viewableShape = PropTypes.shape({
  __typename: viewableType.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  inMyList: PropTypes.bool,
  trailer: PropTypes.string,
  poster: PropTypes.string,
  metaImage: PropTypes.string,
  productionYear: PropTypes.string,
  start: PropTypes.number,
  stop: PropTypes.number,
  duration: PropTypes.number,
  durationHuman: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  offers: PropTypes.arrayOf(offerShape),
  entitlement: entitlementShape,
  episodeNumber: PropTypes.number,
  seasonNumber: PropTypes.number,
  defaultPlayable: playableShape,
  providedBy: PropTypes.shape({
    brand: PropTypes.string.isRequired,
  }),
  related: PropTypes.arrayOf(relatedViewableShape),
  webviews: PropTypes.arrayOf(webviewShape),
  tagsToRender: PropTypes.arrayOf(viewableTagShape),
});
