import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import { formatClockTime } from 'utils/human-format';
import { useTheme } from 'server/theme/hooks';
import {
  Text,
  CleanLink,
  Heading,
  Box,
  Image,
  ClientSideComponent,
} from 'components';
import { ImageTypes } from 'components/Image/Image';
import { TooltipTrigger } from 'components/HoverState';
import RefreshOnExpire from 'components/RefreshOnExpire/RefreshOnExpire';
import ChannelLogo from 'components/ChannelLogo/ChannelLogo';
import { ClickTracker } from 'components/Tracking';
import DeleteIcon from 'components/Icons/DeleteIcon';
import createComponent, { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import * as favoritesActions from 'actions/favorites';
import { ChannelType } from 'types';
import LiveProgressBar from 'components/ProgressBar/LiveProgressBar';
import {
  Container,
  PlayIcon,
  Schedule,
  ScheduleItem,
  StartTime,
  Poster,
  EmptyPoster,
} from './ItemsStyles';

const DeleteButton = createComponent(({ theme }: ComponentRuleProps): RuleStyles => ({
  marginLeft: 'auto',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  color: theme.color.danger,
}), 'button');

const blockTypes = [...Object.values(BLOCK_INTERFACE_TYPES)] as const;

type LiveChannelItemProps = {
  collectionId: string,
  categoryKind: typeof blockTypes,
  channel: ChannelType,
  refreshData: (id?: string) => Promise<any>,
  hideChannelLogo?: boolean,
  editMode: boolean,
};

const POSTER_WIDTH = 292;
const POSTER_HEIGHT = 165;
const LiveChannelItem = (props: LiveChannelItemProps): JSX.Element => {
  const {
    channel,
    refreshData,
    collectionId,
    categoryKind,
    hideChannelLogo,
    editMode,
  } = props;
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const [
    currentBroadcast,
    nextBroadcast,
  ] = channel.schedule || [null, null];

  const channelLogo = hideChannelLogo
    ? null
    : (
      <ChannelLogo
        alt={channel.title}
        src={channel.logoDark}
        withBackground
      />
    );

  const logo = hovered
    ? (<PlayIcon />)
    : channelLogo;

  const handleRemoveChannel = () => {
    dispatch(favoritesActions.removeFavorite(channel.id));
    void refreshData?.();
  };

  const item = currentBroadcast ? (
    <RefreshOnExpire
      expiresAt={currentBroadcast.stop}
      refreshAction={() => refreshData(collectionId)}
    >
      <Container>
        <ClickTracker
          component="ContentList"
          eventName="click_asset"
          clickType="asset"
          collectionId={collectionId}
          categoryKind={categoryKind}
          viewableId={channel.id}
        >
          <TooltipTrigger
            viewable={channel}
            collectionId={collectionId}
            categoryKind={categoryKind}
          >
            <CleanLink
              to={{
                name: 'watch',
                params: {
                  id: channel.id,
                },
              }}
              block
            >
              <Poster
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <Image
                  alt={channel?.title}
                  width={POSTER_WIDTH}
                  height={POSTER_HEIGHT}
                  src={currentBroadcast.banner || ''}
                  type={ImageTypes.sixteenNineBanner}
                  hasShadow={false}
                  rounded
                />
                <Box
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                >
                  {logo}
                </Box>
              </Poster>
              <LiveProgressBar
                start={currentBroadcast.start}
                stop={currentBroadcast.stop}
                extend={{
                  borderBottomLeftRadius: theme.radius.poster,
                  borderBottomRightRadius: theme.radius.poster,
                  overflow: 'hidden',
                }}
              />
            </CleanLink>
          </TooltipTrigger>
        </ClickTracker>

        <Schedule>
          <ScheduleItem>
            <StartTime>
              <ClientSideComponent>{formatClockTime(currentBroadcast.start)}</ClientSideComponent>
            </StartTime>
            <Text fontSize="medium">
              {currentBroadcast.title}
            </Text>
          </ScheduleItem>

          {nextBroadcast && (
            <ScheduleItem>
              <StartTime>
                <ClientSideComponent>{formatClockTime(nextBroadcast.start)}</ClientSideComponent>
              </StartTime>
              {nextBroadcast.title}
            </ScheduleItem>
          )}
        </Schedule>
      </Container>
    </RefreshOnExpire>
  ) : (
    <Container>
      <ClickTracker
        component="ContentList"
        eventName="click_asset"
        clickType="asset"
        collectionId={collectionId}
        categoryKind={categoryKind}
        viewableId={channel.id}
      >
        <CleanLink
          to={{
            name: 'watch',
            params: {
              id: channel.id,
            },
          }}
          block
        >
          <EmptyPoster
            width={POSTER_WIDTH}
            height={POSTER_HEIGHT}
            rounded
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {logo}
          </EmptyPoster>

        </CleanLink>
      </ClickTracker>

      <Heading
        mt="fine"
        mb="medium"
        fontSize="medium"
        wordBreak="break-word"
      >
        {channel.title}
      </Heading>
    </Container>
  );

  return (
    <>
      {
        editMode ? (
          <Box flexBox mx="fine" alignItems="center" justifyContent="space-between" height={theme.margin.xlarge}>
            <Text>
              {channel.title}
            </Text>
            <DeleteButton
              data-tooltip-id="main"
              data-tooltip-i18n="home.removeFavoriteChannel"
              onClick={handleRemoveChannel}
            >
              <DeleteIcon />
            </DeleteButton>
          </Box>
        ) : null
      }
      {item}
    </>
  );
};

export default React.memo(LiveChannelItem);
