import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, ErrorMessage,
  Modal,
  ModalContainer,
  Price,
  PrimaryButton,
  SecondaryButton,
  Text, TextDate,
} from 'components';
import { getImageUrl, RequestStates } from 'utils/helpers';
import { ModalHeading } from 'components/Modal/ModalStyles';
import { DATE_FORMAT, RECURRING_PERIOD } from 'utils/constants';
import createComponent from 'styles/fela/createComponent';
import { Usps } from 'components/Payment';
import logger from 'utils/logger';
import { WINBACK_DISMISS_TYPE } from 'views/Settings/Subscription/CancelPackModal';
import { useDispatch } from 'react-redux';
import * as packsActions from 'actions/packs';
import { useAnalytics } from 'components/Tracking';

export const StrikeText = createComponent(({ theme }) => ({
  textDecoration: 'line-through',
  textDecorationThickness: '3px',
  textDecorationColor: theme.color.crossOutColor,
  '& span': {
    textDecoration: 'line-through',
    textDecorationThickness: '3px',
    textDecorationColor: theme.color.crossOutColor,
  },
}), Text);


export default function WinbackModal({ winback, onDismiss }) {
  const { offerId, id, images, message, name, price, usps, startDate, end: { date: endDate } } = winback;
  const imgUrl = getImageUrl(images, false) || getImageUrl(images, true);
  const [winbackState, setWinbackState] = useState(RequestStates.initial);
  const [winbackCancelState, setWinbackCancelState] = useState(RequestStates.initial);
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  useEffect(() => {
    // send analytics when component is shown
    analytics.onVisible({
      component: 'Winback',
      extra: {
        packId: offerId,
        campaignId: id,
      },
    });
  }, []);

  const onCancelClick = async () => {
    // disable cancel button if winback button is clicked
    if (winbackState === RequestStates.pending) return;

    setWinbackCancelState(RequestStates.pending);
    try {
      analytics.onClick({
        component: 'Winback',
        clickType: 'action',
        eventName: 'click_cancel',
        extra: {
          packId: offerId,
          campaignId: id,
          area: 'cancel-btn',
        },
      });
      await onDismiss(WINBACK_DISMISS_TYPE.CANCEL);
      setWinbackCancelState(RequestStates.success);
    } catch (e) {
      logger.error(e);
      setWinbackCancelState(RequestStates.failed);
    }
  };

  const onWinbackClick = async () => {
    // disable winback button if cancel subscription button is clicked
    if (winbackCancelState === RequestStates.pending) return;

    setWinbackState(RequestStates.pending);
    try {
      analytics.onClick({
        component: 'Winback',
        clickType: 'action',
        eventName: 'click_winback',
        extra: {
          packId: offerId,
          campaignId: id,
          area: 'winback-btn',
        },
      });
      await dispatch(packsActions.applyWinbackPromoCode(id));
      await onDismiss(WINBACK_DISMISS_TYPE.CLOSE);
      setWinbackState(RequestStates.success);
    } catch (e) {
      logger.error(e);
      setWinbackState(RequestStates.failed);
    }
  };

  const onModalDismiss = () => {
    analytics.onClick({
      component: 'Winback',
      clickType: 'navigation',
      eventName: 'click_dismiss',
      extra: {
        packId: offerId,
        campaignId: id,
        area: 'outside-modal',
      },
    });
    onDismiss(WINBACK_DISMISS_TYPE.CLOSE);
  };

  const onCloseIconClick = () => {
    analytics.onClick({
      component: 'Winback',
      clickType: 'navigation',
      eventName: 'click_dismiss',
      extra: {
        packId: offerId,
        campaignId: id,
        area: 'close-icon',
      },
    });

    onDismiss(WINBACK_DISMISS_TYPE.CLOSE);
  };

  return (
    <Modal onDismiss={onModalDismiss}>
      <ModalContainer
        onClickClose={onCloseIconClick}
        withShadow
        withWidth="medium"
        imageUrl={imgUrl}
        message={message}
      >
        <Box row justifyContent="start">
          <ModalHeading>
            {name}
          </ModalHeading>
        </Box>
        <Box row justifyContent="start" mt="medium" mb="medium" gap="xlarge">
          <StrikeText
            id="payment.price"
            bold
            fontSize="smedium"
            color="disabled"
            values={{
              period: price.recurringPeriod,
              price: (
                <Price key="price" price={price.grossPrice} currency={price.currency} />
              ),
            }}
          />
          <Text
            id="payment.price"
            bold
            fontSize="smedium"
            values={{
              period: price.recurringPeriod,
              price: (
                <Price key="price" price={price.netPrice} currency={price.currency} />
              ),
            }}
          />
        </Box>
        <Usps
          usps={usps}
          type="check"
          size="small"
          alignItems="start"
          bold
        />
        <Box row justifyContent="start" mt="fine">
          <Text
            id="winbackModal.fromTo"
            color="secondary"
            italic
            values={{
              from: <TextDate key="winbackPromoFromDate" value={startDate} options={DATE_FORMAT} />,
              to: <TextDate key="winbackPromoToDate" value={endDate} options={DATE_FORMAT} />,
            }}
          />
        </Box>
        <Box
          alignItems="center"
          spaceBetween
          wrap
          mt="fine"
        >
          <Box mt="medium" xs-mx="auto">
            <SecondaryButton
              variant="white"
              minWidth="15rem"
              onClick={onCancelClick}
              showSpinner={winbackCancelState === RequestStates.pending}
              disabled={winbackState === RequestStates.pending}
            >
              <Text id="winbackModal.cancel" />
            </SecondaryButton>
          </Box>
          <Box mt="medium" xs-mx="auto">
            <PrimaryButton
              variant="brand"
              minWidth="15rem"
              onClick={onWinbackClick}
              showSpinner={winbackState === RequestStates.pending}
              disabled={winbackCancelState === RequestStates.pending}
            >
              <Text id="winbackModal.confirm" />
            </PrimaryButton>
          </Box>
        </Box>

        {(winbackState === RequestStates.failed || winbackCancelState === RequestStates.failed) && (
          <Box my="medium">
            <ErrorMessage id="error" />
          </Box>
        )}
      </ModalContainer>
    </Modal>
  );
}
WinbackModal.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  winback: PropTypes.shape({
    offerId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    message: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({
      isDefault: PropTypes.bool.isRequired,
      kind: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    name: PropTypes.string,
    price: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      grossPrice: PropTypes.number.isRequired,
      netPrice: PropTypes.number.isRequired,
      recurringPeriod: PropTypes.oneOf(Object.values(RECURRING_PERIOD)),
    }).isRequired,
    usps: PropTypes.arrayOf(PropTypes.string).isRequired,
    startDate: PropTypes.instanceOf(Date),
    end: PropTypes.shape({
      date: PropTypes.string.isRequired,
    }),
  }),
};
