import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { viewableShape } from 'types/viewableShape';
import { getButtonProps } from './utils';
import OfferButton, { OFFER_BUTTON_AREA } from './OfferButton';

function OfferButtons({ viewable, dataId, onClick, isDetailView = false, area, isPrimaryButtonFullWidth }) {
  const withPayment = useSelector(state => isDetailView ? !!state.settings.features.payment?.content : !!state.settings.features.payment);

  const buttons = useMemo(
    () => !viewable?.entitlement && getButtonProps(viewable?.offers, withPayment),
    [viewable?.offers, withPayment],
  );

  return buttons.map((button, i) => (
    <OfferButton
      dataId={dataId}
      key={button.types}
      viewable={viewable}
      offer={button.offer}
      types={button.types}
      isSingleOffer={button.isSingleOffer}
      isPrimary={!i}
      onClick={onClick}
      area={area}
      isPrimaryButtonFullWidth={isPrimaryButtonFullWidth}
    />
  ));
}

OfferButtons.propTypes = {
  viewable: viewableShape.isRequired,
  dataId: PropTypes.string,
  isDetailView: PropTypes.bool,
  onClick: PropTypes.func,
  area: PropTypes.oneOf([OFFER_BUTTON_AREA.FEATURED, OFFER_BUTTON_AREA.WATCH]),
  isPrimaryButtonFullWidth: PropTypes.bool,
};

export default React.memo(OfferButtons);
