import React, { useEffect, useContext, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useRouter } from 'router';
import { locationShape } from 'router/prop-types';
import { ThemeContext } from 'react-fela';
import {
  BLOCK_INTERFACE_TYPES,
  COLLECTION_DISPLAY_TYPES,
  CONTENT_LIST_SUPPORTED_VIEWABLE_TYPES,
} from 'utils/constants';
import { flattenEdges } from 'utils/helpers';
import * as uiActions from 'actions/ui';
import { useAnalytics } from 'components/Tracking';
import NotFoundView from 'views/NotFoundView/NotFoundView';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import {
  imageTypes,
  Box,
  Text,
  Metadata,
  GridLayout,
  MetadataListLayout,
  Heading,
  Content,
  PageWrapper,
} from 'components';
import CollectionDisplayTypeSwitch from 'components/CollectionDisplayTypeSwitch';
import SixteenNineItem from 'components/VideoItems/SixteenNineItem';
import RegularItem from 'components/VideoItems/RegularItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'components/Spinner/Spinner';
import getCollection from './getCollection.gql';
import GenreHeader from './GenreHeader';

function normalizeViewable(viewable) {
  return {
    ...viewable,
    genres: viewable.genres || [],
  };
}

const VIEWABLES_LIMIT = 22;

function CollectionView({ location }) {
  const router = useRouter();
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const gridRef = useRef(null);
  const refetching = useRef(null);
  const initialPageLoad = useRef(true);

  const {
    collectionDisplayStyles,
    collectionDisplayStyle,
    hideThumbnailChannelLogo,
  } = useSelector(({ settings, ui }) => ({
    collectionDisplayStyles: settings.features.seeAllCollectionDisplay.listStyle,
    collectionDisplayStyle: ui.collectionDisplayStyle,
    hideThumbnailChannelLogo: !!settings.features.hideThumbnailChannelLogo,
  }), shallowEqual);

  const collectionId = location.params.id;
  const collectionDisplayType = collectionDisplayStyle || collectionDisplayStyles[0];

  const {
    loading,
    error,
    data,
    refetch,
    fetchMore,
    client,
  } = useQuery(
    getCollection,
    {
      variables: {
        collectionId,
        blocksFirst: VIEWABLES_LIMIT,
      },
      skip: !location.params.id,
      notifyOnNetworkStatusChange: true,
    },
  );

  const hasMore = data?.viewer?.block?.viewables?.pageInfo.hasNextPage;

  const handleFetchMore = () => {
    const cursor = data?.viewer?.block?.viewables?.pageInfo?.endCursor;
    fetchMore({
      variables: {
        cursor,
      },
    });
  };

  useEffect(() => {
    if (data?.viewer?.block?.__typename === BLOCK_INTERFACE_TYPES.BookmarksCollection) {
      refetching.current = true;
      refetch().then(() => {
        refetching.current = false;
      });
    }
  }, []);

  useEffect(() => {
    // fetch data on initial page load until last item in grid view is out of page
    if (initialPageLoad.current && collectionDisplayType === COLLECTION_DISPLAY_TYPES.grid
        && data && hasMore && gridRef.current && !loading && !refetching.current) {
      const gridChildren = gridRef.current?.children;
      const lastGridItem = gridChildren[gridChildren.length - 1];
      const lastGridItemTopPosition = lastGridItem.getBoundingClientRect().top;
      const { clientHeight } = document.documentElement;
      if (lastGridItemTopPosition < clientHeight) {
        handleFetchMore();
      } else {
        initialPageLoad.current = false;
      }
    }
  }, [collectionDisplayType, data, hasMore, loading]);

  const setHeaderTransparency = () => {
    // Force header opaque if collection doesn't have an image
    const withTransparentHeader = !!(data?.viewer.block?.image);
    dispatch(
      uiActions.setHeaderTransparency(location.name, location.params.id, withTransparentHeader),
    );
  };

  useEffect(() => {
    setHeaderTransparency();
  }, [dispatch, data, location.name, location.params.id]);

  if ((loading && !data) || error) {
    return null;
  }

  if (__SERVER__) {
    // trigger dispatch on SSR step
    setHeaderTransparency();
  }

  if (!data?.viewer.block) {
    return (
      <NotFoundView />
    );
  }

  const {
    __typename: typename,
    title,
    description,
    image,
    collectionUI: collectionUIStr = null,
    viewables: rawViewables,
  } = data?.viewer.block;

  const collectionUI = JSON.parse(collectionUIStr);
  const hideChannelLogo = collectionUI?.hideChannelLogo ?? hideThumbnailChannelLogo;

  const viewables = flattenEdges(rawViewables)
    .filter(Boolean)
    .filter(viewable => CONTENT_LIST_SUPPORTED_VIEWABLE_TYPES.includes(viewable.__typename))
    .map(normalizeViewable);

  const trackClick = (viewableId, playableId) => {
    const activeRoute = router.getActiveRoute();

    analytics.onClick({
      component: activeRoute.pageAnalytics.component,
      clickType: 'asset',
      eventName: 'click_asset',
      element: 'asset',
      viewableId,
      playableId,
    });
  };

  const handleDisplayTypeSwitchClick = type => dispatch(
    uiActions.setCollectionDisplayStyle(type),
  );

  const handleRefreshData = async (viewable) => {
    if (typename !== BLOCK_INTERFACE_TYPES.BookmarksCollection) return;
    // remove viewable from Bookmarks collection.
    const nextEdges = data.viewer.block.viewables.edges
      .filter(({ node }) => node.id !== viewable.id);

    client.writeQuery({
      query: getCollection,
      data: {
        ...data,
        viewer: {
          ...data.viewer,
          block: {
            ...data.viewer.block,
            viewables: {
              ...data.viewer.block.viewables,
              edges: nextEdges,
            },
          },
        },
      },
    });
  };

  const showDisplayStyleSwitch = collectionDisplayStyles.length === 2 && viewables.length > 0;

  let ItemComponent = RegularItem;
  let itemWidth = imageTypes.poster.width;
  let is16x9 = false;

  if (typename === BLOCK_INTERFACE_TYPES.ContinueWatchingCollection
    || typename === BLOCK_INTERFACE_TYPES.SixteenNineCollection) {
    ItemComponent = SixteenNineItem;
    itemWidth = imageTypes.sixteenNineBanner.width;
    is16x9 = true;
  }

  return (
    <PageWrapper background>
      <Metadata
        title={title}
        description={description}
        imageUrl={image}
      />
      {image && (
        <GenreHeader
          title={title}
          description={description}
          image={image}
        />
      )}
      <Content>
        <Box
          display={showDisplayStyleSwitch ? 'flex' : 'none'}
          justifyContent="flex-end"
          mb="xlarge"
        >
          <CollectionDisplayTypeSwitch
            selected={collectionDisplayType}
            onClick={handleDisplayTypeSwitchClick}
          />
        </Box>

        {!image && (
          <Box mt="large">
            <Heading
              fontSize="sectionHeading"
              mb="medium"
              {...(theme.collectionTitle ? {
                fontWeight: theme.collectionTitle?.weight,
                fontStyle: theme.collectionTitle?.style,
              } : {})}
            >
              {title}
            </Heading>
          </Box>
        )}

        <InfiniteScroll
          style={{ overflowY: 'hidden' }}
          dataLength={viewables.length}
          next={handleFetchMore}
          hasMore={hasMore}
          loader={<Box column justifyContent="center" pt="xxxlarge" pb="xxxlarge"><Spinner size={3} /></Box>}
        >
          {collectionDisplayType === COLLECTION_DISPLAY_TYPES.grid && (
            <GridLayout
              innerRef={gridRef}
              items={viewables}
              onClick={trackClick}
              refreshData={handleRefreshData}
              ItemComponent={ItemComponent}
              itemWidth={itemWidth}
              is16x9={is16x9}
              hideChannelLogo={hideChannelLogo}
              collectionId={collectionId}
              categoryKind={typename}
            />
          )}

          {collectionDisplayType === COLLECTION_DISPLAY_TYPES.list && (
            <MetadataListLayout
              items={viewables}
              onClick={trackClick}
              refreshData={handleRefreshData}
              ItemComponent={ItemComponent}
              hideChannelLogo={hideChannelLogo}
              collectionId={collectionId}
              categoryKind={typename}
            />
          )}
        </InfiniteScroll>

        {viewables.length === 0 && (
          <Box mt="xxxlarge">
            <ErrorPage>
              <Text id="genre.noContent" />
            </ErrorPage>
          </Box>
        )}
      </Content>
    </PageWrapper>
  );
}

CollectionView.propTypes = {
  location: locationShape.isRequired,
};

export default React.memo(CollectionView);
