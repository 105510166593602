import React, { memo, useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useSelector } from 'reducers';
import { useFela } from 'react-fela';
import { GlobalTheme } from 'types';
import HotkeyOverlay from 'components/HotkeyOverlay/HotkeyOverlay';
import NextRecommendation from 'components/NextRecommendation';
import { PlayerContext } from './PlayerContext';
import { PlayerArea, Container } from './Styles';
import PlayerParentalControlModal from './PlayerParentalControlModal';
import { useTranslatedPlayerMessages, isLiveDisabled } from './utils';

interface IPlayerContainerProps {
  isOnline: boolean;
}

function PlayerContainer({ isOnline }: IPlayerContainerProps) {
  const {
    player,
    viewableId,
    isPlayedToEnd,
    mini,
    restoreFromMiniPlayer,
    closeMiniPlayer,
    isPinCodeRequired,
    playerError,
    hotkeyAction,
    viewable,
    deactivateFullScreen,
    nextRecommendationActive,
  } = useContext<any>(PlayerContext); // FIXME

  const { theme, renderer: felaRenderer } = useFela<GlobalTheme>();

  const {
    language,
    adsShowLogo,
    headerLogo,
    seekStepInSeconds,
  } = useSelector(state => ({
    language: state.settings.l10n.language,
    headerLogo: state.settings.brand.headerLogo,
    adsShowLogo: state.settings.features.ads?.showLogo || false,
    seekStepInSeconds: state.settings.features.player.seekStepInSeconds,
  }), shallowEqual);

  const hidden = !viewableId
    || (isPlayedToEnd && !mini && !nextRecommendationActive)
    || (playerError && !mini)
    || (isLiveDisabled(viewable) && !mini);

  const messages = useTranslatedPlayerMessages(language);

  const [preOfflinePosition, setPreOfflinePosition] = useState(undefined);
  const [preOfflineIsPlaying, setPreOfflineIsPlaying] = useState(false);

  useEffect(() => {
    if (!player) {
      return;
    }

    const ads = adsShowLogo ? {
      logo: headerLogo,
      logoWidth: theme.ads.logo.width || theme.header.logo.width,
    } : undefined;

    player.renderControls({
      mini,
      ads,
      seekStepInSeconds,
      restoreFromMiniPlayer,
      closeMiniPlayer,

      felaRenderer,

      language,
      messages,
      theme,
    });

    if (!isOnline && !preOfflinePosition) {
      player.pause();
      deactivateFullScreen();
      setPreOfflinePosition(player.model?.currentTime);
      setPreOfflineIsPlaying(player.model?.isPlaying);
    }
    if (isOnline && preOfflinePosition) {
      player.seek(preOfflinePosition).then(() => {
        if (preOfflineIsPlaying) {
          player.play();
        }
      });
      setPreOfflinePosition(undefined);
    }
  }, [player, mini, isOnline, preOfflinePosition, preOfflineIsPlaying]);

  return (
    <PlayerArea id="playerArea" mini={mini} hidden={hidden} fullPageMode>
      <NextRecommendation />
      <Container id="player" />
      <HotkeyOverlay action={hotkeyAction?.action} timestamp={hotkeyAction?.timestamp} />
      {isPinCodeRequired && !mini && (
        <PlayerParentalControlModal />
      )}
    </PlayerArea>
  );
}

export default memo(PlayerContainer);
