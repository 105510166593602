import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';

const Container = createComponent(({ theme, hide }) => ({
  position: 'fixed',
  bottom: '0',
  right: '0',
  left: '0',
  top: '0',
  zIndex: theme.zIndex.modal,

  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  background: theme.color.overlayModal,
  userSelect: 'none', // without this click on container selects text in modal
  transition: 'opacity .3s',
  overflow: 'auto',

  extend: [
    {
      condition: hide,
      style: {
        opacity: 0,
      },
    },
  ],
}), 'div', ['hide']);

const ModalWrapper = createComponent(() => ({
  userSelect: 'initial', // so now we can select text in modal
  display: 'flex',
  justifyContent: 'center',
  marginTop: 'auto',
  marginBottom: 'auto',
  position: 'relative',
}));

export default function Modal({
  className,
  children,
  onDismiss,
  hide,
}) {
  const [didMount, setDidMount] = useState(false);
  const containerRef = useRef();
  const wrapperRef = useRef();

  const onDismissHandler = (e) => {
    if (e.target === wrapperRef.current || e.target === containerRef.current) {
      onDismiss?.();
    }
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      if (onDismiss && e.key === 'Escape') {
        onDismiss();
      }
    };

    setDidMount(true);
    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  // make sure a DOM element for the portal already exists
  if (!didMount) {
    return null;
  }

  return ReactDOM.createPortal(
    <Container
      className={className}
      onClick={onDismissHandler}
      innerRef={containerRef}
      hide={hide}
    >
      <ModalWrapper innerRef={wrapperRef}>
        {children}
      </ModalWrapper>
    </Container>,
    document.getElementById('modal'),
  );
}

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func,
  hide: PropTypes.bool,
};
