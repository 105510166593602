export const SET_COOKIE_POLICY_NOTIFICATION_SHOWN = 'cookie/SET_COOKIE_POLICY_NOTIFICATION_SHOWN';
interface SetCookiePolicyNotificationShownAction {
  type: typeof SET_COOKIE_POLICY_NOTIFICATION_SHOWN
  show: boolean,
}
export const setCookiePolicyNotificationShown = (show: boolean): SetCookiePolicyNotificationShownAction => ({
  type: SET_COOKIE_POLICY_NOTIFICATION_SHOWN,
  show,
});

export const SET_COOKIE_CONSENT = 'cookie/SET_COOKIE_CONSENT';

type CookieConsent = {
  necessary: boolean,
  marketing: boolean,
  preferences: boolean,
  statistics: boolean,
};
interface SetCookieConsentAction {
  type: typeof SET_COOKIE_CONSENT
  consent: CookieConsent,
}
export const setCookieConsent = (consent: CookieConsent): SetCookieConsentAction => ({
  type: SET_COOKIE_CONSENT,
  consent,
});

export type CookieActions = (
  SetCookiePolicyNotificationShownAction | SetCookieConsentAction
);
