import { transparentize } from 'polished';
import createComponent from 'styles/fela/createComponent';
import BasicButton from './BasicButton';

const SecondaryButton = createComponent(({
  theme,
  variant = 'brand',
  px,
  link,
}) => ({
  border: '2px solid',
  background: 'transparent',
  lineHeight: '3.3rem',
  transition: 'background 0.2s ease-in-out',

  fontWeight: 'bold',

  ':hover:not([disabled])': {
    background: theme.color.secondaryButtonHover,
  },

  extend: [
    {
      condition: variant === 'brand',
      style: {
        borderColor: theme.color.brand,
        color: theme.color.brand,
      },
    },
    {
      condition: variant === 'white',
      style: {
        color: 'white',
        borderColor: 'transparent',
        background: theme.color.whiteButtonBackground,
        ':hover:not([disabled])': {
          background: theme.color.whiteButtonBackgroundHover,
        },
      },
    },
    {
      condition: variant === 'brandSecondary',
      style: {
        borderColor: theme.color.brandSecondary,
        color: theme.color.brandSecondary,
        '&[disabled]': {
          borderColor: transparentize(0.3, theme.color.brandSecondary),
          color: transparentize(0.3, theme.color.brandSecondary),
        },
      },
    },
    {
      condition: variant === 'contrast',
      style: {
        borderColor: theme.color.contrastColor,
        color: theme.color.contrastColor,
        '&[disabled]': {
          color: theme.color.contrastColor,
        },
      },
    },
    {
      condition: px,
      style: {
        paddingLeft: theme.margin[px] || px,
        paddingRight: theme.margin[px] || px,
      },
    },
    {
      condition: link,
      style: {
        color: theme.color.light,
      },
    },
  ],
}), BasicButton, ['variant', 'px', 'link']);

SecondaryButton.displayName = 'SecondaryButton';

export default SecondaryButton;
