import { Action, combineReducers, Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';

import { routerReducer } from 'router/reducer';
import { authReducer } from './auth';
import { epgReducer } from './epg';
import { scrollableReducer } from './scrollable';
import { settingsReducer } from './settings';
import { userReducer } from './user';
import { billingReducer } from './billing';
import { countryReducer } from './country';
import { commonReducer } from './common';
import { packsReducer } from './packs';
import { offersReducer } from './offers';
import { copyReducer } from './copy';
import { messagesReducer } from './messages';
import { uiReducer } from './ui';
import { pageReducer } from './page';
import { cookieReducer } from './cookie';

export const rootReducer = combineReducers({
  auth: authReducer,
  billing: billingReducer,
  epg: epgReducer,
  scrollable: scrollableReducer,
  settings: settingsReducer,
  user: userReducer,
  country: countryReducer,
  common: commonReducer,
  packs: packsReducer,
  offers: offersReducer,
  copy: copyReducer,
  messages: messagesReducer,
  router: routerReducer,
  ui: uiReducer,
  page: pageReducer,
  cookie: cookieReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = ThunkDispatch<RootState, unknown, Action>;

export type AppStore = Store<RootState, Action> & {
  dispatch: AppDispatch,
};

export const useDispatch = ():AppDispatch => useReduxDispatch<AppDispatch>();
export const useSelector:TypedUseSelectorHook<RootState> = useReduxSelector;
