// @ts-nocheck
import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
export type TContentListTrailerFragmentFragment = { __typename?: 'Trailer', id: string, defaultPlayable?: { __typename?: 'BroadcastPlayable', id: string } | { __typename?: 'ChannelPlayable', id: string } | { __typename?: 'LiveEventPlayable', id: string } | { __typename?: 'VodPlayable', id: string } | null };

export const ContentListTrailerFragmentFragmentDoc = gql`
    fragment ContentListTrailerFragment on Trailer {
  id: magineId
  defaultPlayable {
    id
  }
}
    `;