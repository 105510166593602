/* eslint-disable react/no-array-index-key */

import React, { useMemo } from 'react';
import {
  Box,
  BoxProps,
  Text,
} from 'components';
import { CheckIcon } from 'components/Icons';

const uspSizes = {
  small: {
    wrapper: {
      marginBottom: 'fine',
    },
    icon: {
      size: 20,
      marginTop: -3,
    },
    text: {
      fontSize: 'normal',
    },
  },
  normal: {
    wrapper: {
      marginBottom: 'normal',
    },
    icon: {
      size: 24,
      marginTop: 0,
    },
    text: {
      fontSize: '1.3rem',
    },
  },
};

interface IUspsProps extends BoxProps {
  usps?: string[];
  withIcons?: boolean;
  size?: 'normal' | 'small';
  color?: string;
  type?: string;
}

const Usps = React.forwardRef<any, IUspsProps>((props, ref) => {
  const {
    usps = [],
    withIcons = true,
    size = 'normal',
    color = 'primary',
    type = 'check',
    bold,
    ...restProps
  } = props;
  const { wrapper, icon, text } = useMemo(() => uspSizes[size], [size]);

  if (!usps || !usps?.length) {
    return null;
  }

  const getIcon = () => {
    if (!withIcons) return null;

    return type === 'check' ? (
      <Box
        mr="fine"
        mt={icon.marginTop}
      >
        <CheckIcon
          width={icon.size}
          height={icon.size}
          color={color}
        />
      </Box>
    ) : (
      <Box
        width={icon.size}
        alignItems="left"
        mr="fine"
        pl="4px"
        color={color}
        flex="0 0 auto"
      >
        •
      </Box>
    );
  };

  return (
    <Box innerRef={ref} column {...restProps}>
      {
        usps.map((usp, index) => (
          <Box
            key={`${index}-${usp}`}
            mb={wrapper.marginBottom}
            row
            fullWidth
            justifyContent="flex-start"
            maxWidth="30rem"
            fontSize={text.fontSize}
          >
            {getIcon()}
            <Text
              align="left"
              color={color}
              bold={bold}
            >
              {usp}
            </Text>
          </Box>
        ))
      }
    </Box>
  );
});

Usps.displayName = 'UspsComponent';

export default React.memo(Usps);
