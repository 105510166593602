import * as Sentry from '@sentry/react';
import { API_URL } from 'server/middlewares/constants';
import { getPersistentRandomId } from 'utils/helpers';
import storage from 'utils/storage';

export const getSentryReplyIntegrationSettings = env => ({
  maskAllText: false,
  blockAllMedia: false,
  maskAllInputs: true,
  networkDetailAllowUrls: [API_URL[env]], // get api response body
});

export const configureSentry = (settings) => {
  const {
    app: {
      version,
      magineEnvironment,
    },
    partner,
  } = settings;
  if (__CLIENT__ && __PRODUCTION__) {
    Sentry.init({
      dsn: 'https://2ef7296c09f5a366476a0d825657c3ee@o4507260367863808.ingest.de.sentry.io/4507260630925392',
      release: version,
      environment: magineEnvironment,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(getSentryReplyIntegrationSettings(magineEnvironment)),
        Sentry.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.1, //  Capture 10% of the transactions

      // Session Replay
      replaysSessionSampleRate: 0, // no session replay will be captured.
      replaysOnErrorSampleRate: 1.0, // 100% when sampling sessions where errors occur.

      beforeBreadcrumb: (breadcrumb) => {
        // only capture warn and error level console log
        if (breadcrumb.category === 'console' && ['debug', 'info', 'log'].includes(breadcrumb.level)) {
          return null;
        }
        return breadcrumb;
      },

      beforeSend(event) {
        event.extra = {
          ...event.extra,
          userLocalStorage: storage.clone(),
        };
        return event;
      },
    });

    Sentry.setTag('partner', partner);

    const id = getPersistentRandomId('deviceId');
    Sentry.setUser({
      id,
    });
  }
};
