import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSpring, animated } from 'react-spring';
import useResizeObserver from 'use-resize-observer';
import {
  ModalHeading,
} from 'components/Modal/ModalStyles';
import {
  Modal,
  ModalContainer,
  PrimaryButton,
  Box,
  Text,
} from 'components';
import {
  usePromoCodeForm,
  PromoCodeInput,
} from 'components/Payment';
import Usps from 'components/Payment/Usps';
import { TOffer } from 'views/Checkout/types';

const AnimatedBox = animated(Box);

interface IPromoCodeModalProps {
  initialValue?: string;
  validatePromocode: (newPromoCode: string) => Promise<void>;
  onClose: () => void;
  offer?: TOffer;
}

function PromoCodeModal(props: IPromoCodeModalProps) {
  const { validatePromocode, onClose, initialValue, offer } = props;
  const {
    methods,
    inProgress,
    onSubmit,
    usps,
  } = usePromoCodeForm(initialValue, validatePromocode, offer);
  const { ref: uspsRef, height: viewHeight } = useResizeObserver();

  const [uspsBoxProps, set] = useSpring(() => ({ height: 0, opacity: 0 }));

  const asyncAnimate = () => {
    set({ height: viewHeight });
    set({ to: { opacity: usps.length > 0 ? 1 : 0 }, delay: 250 });
  };

  useEffect(
    () => void asyncAnimate(),
    [viewHeight],
  );

  return (
    <Modal onDismiss={onClose}>
      <ModalContainer
        onClickClose={onClose}
        withShadow
        withWidth="medium"
      >
        <Box
          p="small"
          column
        >
          <Box mt="0.83rem" mb="0.7rem">
            <ModalHeading id="promoCode.header" />
          </Box>

          <FormProvider {...methods}>
            <Box
              as="form"
              onSubmit={onSubmit}
              mt="large"
              fullWidth
              method="POST"
            >
              <Box
                sm-row
                mb="small"
                fullWidth
                gap="small"
              >
                <Box
                  flex="1"
                  className="e2e-promocode-input"
                >
                  <PromoCodeInput autoFocus />
                </Box>

                <PrimaryButton
                  minWidth="8rem"
                  variant="brand"
                  type="submit"
                  showSpinner={inProgress}
                  className="e2e-promocode-apply"
                >
                  <Text id="promoCode.submit" />
                </PrimaryButton>
              </Box>

              <AnimatedBox style={uspsBoxProps}>
                <Usps
                  mt="medium"
                  usps={usps}
                  size="small"
                  ref={uspsRef}
                />
              </AnimatedBox>
            </Box>
          </FormProvider>
        </Box>
      </ModalContainer>
    </Modal>
  );
}

export default React.memo(PromoCodeModal);
