import React, { useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { PlayerContext } from 'components/Player';
import NextInfo from 'views/WatchView/ViewableInfo/NextInfo';
import { EXIT_FULL_WINDOW_AREA } from 'utils/constants';

function NextRecommendation() {
  const playerContext = useContext(PlayerContext) as any;
  const {
    player,
    viewable,
    isPlayedToEnd,
    nextRecommendation,
    nextRecommendationActive: visibile,
  } = playerContext;

  const recommendationSettings = useSelector(
    (state: RootState) => state.settings.features.playNext?.recommendation,
    shallowEqual,
  );

  if (!visibile || !nextRecommendation || !recommendationSettings) return null;

  const stopHandler = () => {
    player._exitFullWindow(EXIT_FULL_WINDOW_AREA.NEXT_BACK_ICON);
  };

  const initHandler = () => {
    player._aspectratioService.updateAspectRatio();
  };

  return (
    <NextInfo
      player={player}
      viewable={nextRecommendation}
      originalViewable={viewable}
      onClose={stopHandler}
      onInit={initHandler}
      hidePlayer={isPlayedToEnd}
    />
  );
}

export default React.memo(NextRecommendation);
