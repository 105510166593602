import React from 'react';
import PropTypes from 'prop-types';
import { viewableShape } from 'types/viewableShape';
import { Box, Text } from 'components';
import PrimaryButton from 'components/Button/PrimaryButton';
import { PlayBtnIcon } from 'components/Icons';

function PlayButton({ viewable, onPlay, disabled, time, ...props }) {
  return (
    <PrimaryButton
      onClick={onPlay}
      variant="brand"
      minWidth="auto"
      fullWidth
      sm-fullWidth={false}
      disabled={disabled}
      data-cy="play-button"
      {...props}
    >
      <Box row alignItems="center" gap="fine">
        <PlayBtnIcon />
        <span>
          {viewable?.defaultPlayable?.watchOffset ? (
            <Text id="continueWatching" />
          ) : (
            <Text id="watch.play" />
          )} {viewable?.seasonNumber ? (
            <Text
              id="seasonEpisodeShort"
              values={{
                seasonNumber: viewable.seasonNumber,
                episodeNumber: viewable.episodeNumber,
              }}
            />
          ) : null}

          {time !== undefined && (
            <Text
              id="nextVideo.timer"
              values={{ time }}
            />
          )}
        </span>
      </Box>
    </PrimaryButton>
  );
}

PlayButton.propTypes = {
  viewable: viewableShape,
  onPlay: PropTypes.func,
  disabled: PropTypes.bool,
  time: PropTypes.number,
};

export default React.memo(PlayButton);
