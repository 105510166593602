import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import createComponent, { RuleStyles, ComponentRuleProps } from 'styles/fela/createComponent';
import { useFela } from 'react-fela';
import { useI18n } from 'components/I18n';
import { useOnlineStatus } from 'utils/hooks';
import { useTranslatedPlayerMessages } from 'components/Player/utils';
import { useSelector } from 'reducers';
import storage from 'utils/storage';
import type MagineMediaPlayer from '@tvoli/hipster-player';

const VideoContainer = createComponent(({ theme }: ComponentRuleProps): RuleStyles => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',

  zIndex: theme.zIndex.trailerPlayer,

  ':fullscreen': {
    height: '100%',
    maxHeight: '100%',
  },
}), 'div');

VideoContainer.displayName = 'VideoContainer';

interface ITrailerPlayerProps {
  title: string;
  trailerUrl?: string;
  trailers: {
    id: string;
    defaultPlayable: {
      id: string;
    }
  }[];
  onClose: () => void;
}

export default function TrailerPlayer(props: ITrailerPlayerProps): JSX.Element {
  const { title, trailerUrl, trailers, onClose } = props;
  const { theme, renderer: felaRenderer } = useFela();
  const [player, setPlayer] = useState<MagineMediaPlayer | null>(null);
  const {
    apiBaseUri,
    language,
    clientApiToken,
    sessionToken,
    defaultAudioTrack,
    muxKey,
    userId,
    partner,
  } = useSelector(state => ({
    apiBaseUri: state.settings.apiBaseUri,
    language: state.settings.l10n.language,
    clientApiToken: state.settings.clientApiToken,
    sessionToken: state.auth.sessionToken,
    defaultAudioTrack: state.settings.features.player.defaultAudioTrack,
    muxKey: state.settings.features.mux?.id,
    userId: state.auth.userId,
    partner: state.settings.partner,
  }), shallowEqual);

  const messages = useTranslatedPlayerMessages(language);

  const [preOfflinePosition, setPreOfflinePosition] = React.useState<number | undefined>(undefined);
  const [preOfflineIsPlaying, setPreOfflineIsPlaying] = React.useState(false);
  const isOnline = useOnlineStatus();
  const i18n = useI18n();

  const isHosted = !trailers?.[0];

  const playLinked = (viewableId: string, playableId: string) => {
    void player?.loadWithPreflight({
      accessToken: clientApiToken,
      ads: false,
      assetId: playableId,
      viewableId,
      viewable: {
        id: viewableId,
        title: i18n.formatText('trailer.title', { title }),
      },
      authorizationKey: sessionToken,
      playbackStartTime: 0,
    });
  };

  const playHosted = (playlist: string) => {
    void player?.playHostedVideo({
      playlist,
      assetId: playlist,
      viewableId: playlist,
      ads: false,
      viewable: {
        id: playlist,
        title: i18n.formatText('trailer.title', { title }),
      },
      playbackStartTime: 0,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const playTrailers = useCallback( () => {
    const trailer = trailers?.[0];

    if (trailer) {
      playLinked(
        trailer.id,
        trailer.defaultPlayable.id,
      );
    } else if (trailerUrl) {
      playHosted(trailerUrl);
    }
  }, [player]);

  const initPlayer = (node: HTMLElement) => {
    if (player) {
      return player;
    }

    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const HipsterPlayer = require('@tvoli/hipster-player').default;

    const hipsterPlayer = new HipsterPlayer({
      baseUri: apiBaseUri,
      logLevel: __PRODUCTION__ ? 1 : 10,
      preferredAudio: storage.getItem('audioTrack') || defaultAudioTrack,
      playerContainer: node,
      muxKey,
      userId,
      partner,
      disableServices: {
        heartbeat: isHosted,
        telemetry: isHosted,
        chromecast: isHosted,
        dai: true,
        aspectratio: true,
      },
      ...(isHosted ? { type: 'hosted' } : null),
    });

    hipsterPlayer.renderControls({
      language,
      messages,
      theme,
      felaRenderer,
      hide: {
        audio: isHosted,
        quality: isHosted,
        cast: isHosted,
      },
    });


    setPlayer(hipsterPlayer);

    return hipsterPlayer;
  };

  useEffect(() => {
    if (player) {
      void playTrailers();

      player.addEventListener('exitFullWindow', handleClose);
      player.addEventListener('streamEnded', handleClose);

      return () => {
        player.removeEventListener('exitFullWindow', handleClose);
        player.removeEventListener('streamEnded', handleClose);
        setTimeout(() => void player.destroy());
      };
    }

    return () => {};
  }, [player]);

  useEffect(() => {
    if (!player) {
      return;
    }
    if (!isOnline && !preOfflinePosition) {
      void player.pause();
      setPreOfflinePosition(player.model?.currentTime);
      setPreOfflineIsPlaying(player.model?.isPlaying);
    }
    if (isOnline && preOfflinePosition) {
      void player.seek(preOfflinePosition).then(() => {
        if (preOfflineIsPlaying) {
          void player.play();
        }
      });
      setPreOfflinePosition(undefined);
    }
  }, [isOnline, preOfflinePosition, preOfflineIsPlaying]);

  const playerContainerRef = useCallback((node: HTMLElement) => {
    if (node) {
      initPlayer(node);
    }
  }, []);

  return (
    <VideoContainer innerRef={playerContainerRef} />
  );
}
