import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import PrimaryButton from 'components/Button/PrimaryButton';
import { Box } from 'components/StyledSystem/Box';
import Text from 'components/StyledSystem/Text';
import ErrorMessage from 'components/StyledSystem/ErrorMessage';
import logger from 'utils/logger';
import { useResetPinCodeMutation } from './resetPinCode.generated';

type ResetPinProps = {
  onSuccess: () => void,
  onClose: () => void,
  compact?: boolean,
  preventPinReset?: boolean,
  withButtonWidth?: string,
};

export default function ResetPin({
  compact = false,
  withButtonWidth = '',
  preventPinReset,
  onSuccess,
  onClose,
}: ResetPinProps) {
  const {
    email,
  } = useSelector((state: RootState) => ({
    email: state.user.contactEmail,
  }));

  const [resetPinCodeMutation, { loading, error }] = useResetPinCodeMutation();

  const sendNewPinCode = async () => {
    if (preventPinReset) {
      onSuccess();
    } else {
      try {
        const result = await resetPinCodeMutation();

        if (result.errors) {
          throw new Error(result.errors[0]?.message);
        }

        onSuccess();
      } catch (e) {
        logger.warn('Reset pin request failed with error', e);
      }
    }
  };

  const renderWillSendButton = () => {
    const extraProps = withButtonWidth ? { withWidth: withButtonWidth } : {};

    return (
      <PrimaryButton
        onClick={sendNewPinCode}
        showSpinner={loading}
        {...extraProps}
      >
        <Text id="appSettings.sendNewPinCodeModalBtnOk" />
      </PrimaryButton>
    );
  };

  const renderSendFailedButton = () => {
    const extraProps = withButtonWidth ? { withWidth: withButtonWidth } : {};

    return (
      <PrimaryButton
        variant="brand"
        onClick={onClose}
        {...extraProps}
      >
        <Text id="appSettings.sendNewPinCodeModalFailedBtnText" />
      </PrimaryButton>
    );
  };

  const renderButton = () => {
    if (!error) {
      return renderWillSendButton();
    }
    return renderSendFailedButton();
  };

  return (
    <Box column>
      { !error && (
        <Box
          mt={compact ? 'none' : 'large'}
        >
          <Text
            id="appSettings.sendNewPinCodeModalInfo"
            values={{ email }}
            align="center"
          />
        </Box>
      )}

      { !!error && (
        <Box
          mt={compact ? 'none' : 'large'}
        >
          <ErrorMessage
            id="appSettings.resetParentalControlFailure"
            align="center"
          />
        </Box>
      )}

      <Box my={compact ? 'large' : 'xxxlarge'}>
        { renderButton() }
      </Box>
    </Box>
  );
}
