import {
  SET_COOKIE_POLICY_NOTIFICATION_SHOWN,
  SET_COOKIE_CONSENT,
  CookieActions,
} from 'actions/cookie';

export type CookieReducerState = {
  cookiePolicyNotificationShown: boolean
  cookieConsent: Record<string, boolean>,
};

const defaultState: CookieReducerState = {
  cookiePolicyNotificationShown: false,
  cookieConsent: {
    necessary: true,
    marketing: false,
    preferences: false,
    statistics: false,
  },
};

export function cookieReducer(state = defaultState, action: CookieActions): CookieReducerState {
  switch (action.type) {

    case SET_COOKIE_POLICY_NOTIFICATION_SHOWN: {
      return {
        ...state,
        cookiePolicyNotificationShown: action.show,
      };
    }

    case SET_COOKIE_CONSENT: {
      return {
        ...state,
        cookieConsent: action.consent,
      };
    }

    default: {
      return state;
    }
  }
}
