import React from 'react';
import PropTypes from 'prop-types';
import { OFFER_TYPES } from 'utils/constants';
import { useOfferButtonClickHandler } from 'utils/hooks';
import { offerShape, viewableShape } from 'types/viewableShape';
import {
  Price,
  Box,
  PrimaryButton,
  SecondaryButton,
  Text,
} from 'components';
import { PlayBtnIcon } from 'components/Icons';

export const OFFER_BUTTON_AREA = {
  FEATURED: 'featured',
  WATCH: 'watch',
};

const MSG_IDS = {
  [OFFER_TYPES.BuyType]: 'offer.buy',
  [OFFER_TYPES.RentType]: 'offer.rent',
  [OFFER_TYPES.SubscribeType]: 'offer.subscribe',
  [OFFER_TYPES.PassType]: 'offer.pass',
  [OFFER_TYPES.DefaultType]: 'offer.signup',
};

function getButtonLabel(offer, isSingleOffer) {
  // second button includes all other offer types
  if (!offer) {
    return (<Text id="offer.multi" />);
  }

  if (offer.__typename === OFFER_TYPES.DefaultType) {
    return (
      <Box row alignItems="center" gap="fine">
        <PlayBtnIcon />
        <Text
          lineHeight="1.3em"
          id={MSG_IDS[OFFER_TYPES.DefaultType]}
        />
      </Box>
    );
  }

  const price = (
    <Price key="price" price={offer.priceInCents} currency={offer.currency} />
  );

  return (
    <>
      <Text
        id={MSG_IDS[offer.__typename]}
      /> {isSingleOffer ? price : (
        <Text
          id="fromPrice"
          values={{ price }}
        />
      )}
    </>
  );
}

function OfferButton({
  viewable,
  offer,
  isSingleOffer,
  types,
  isPrimary,
  dataId,
  onClick,
  area,
  isPrimaryButtonFullWidth,
}) {
  const offerButtonClickHandler = useOfferButtonClickHandler(viewable);
  const Component = isPrimary ? PrimaryButton : SecondaryButton;

  const clickHandler = () => {
    offerButtonClickHandler(types);
    onClick?.(types);
  };

  return (
    <Box width="100%"
        {...isPrimary && isPrimaryButtonFullWidth ? {} : { 'sm-width': 'auto' } }
        {...!isPrimary && isPrimaryButtonFullWidth ? { 'sm-flex': 1 } : {} }
        {...area === OFFER_BUTTON_AREA.FEATURED  ? {} : { 'sm-mr': 'small' } }
    >
      <Component
        variant={isPrimary ? 'brand' : 'white' }
        onClick={clickHandler}
        nowrap
        data-cy={`${types}-button`}
        data-id={dataId}
        data-value={`offer-button/${types}`}
        minWidth="auto"
        fullWidth
        sm-fullWidth={false}
      >
        {getButtonLabel(offer, isSingleOffer)}
      </Component>
    </Box>
  );
}

OfferButton.propTypes = {
  viewable: viewableShape.isRequired,
  offer: offerShape,
  isSingleOffer: PropTypes.bool,
  isPrimary: PropTypes.bool,
  types: PropTypes.string,
  dataId: PropTypes.string,
  onClick: PropTypes.func,
  area: PropTypes.oneOf([OFFER_BUTTON_AREA.FEATURED, OFFER_BUTTON_AREA.WATCH]),
  isPrimaryButtonFullWidth: PropTypes.bool,
};

export default OfferButton;
