// @ts-nocheck
import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TChannelsForChannelPickerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TChannelsForChannelPickerQuery = { __typename?: 'Query', pickerChannels: { __typename?: 'Viewer', id: string, channels: { __typename?: 'UserChannelsConnection', edges?: Array<{ __typename?: 'UserChannelsEdge', node?: { __typename?: 'Channel', title?: string | null, id: string, logoDark?: string | null, logoLight?: string | null } | null } | null> | null } } };


export const ChannelsForChannelPickerDocument = gql`
    query channelsForChannelPicker {
  pickerChannels: viewer {
    id: magineId
    channels {
      edges {
        node {
          ... on Channel {
            id: magineId
            title
            logoDark: image(type: "logo-dark")
            logoLight: image(type: "logo-light")
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChannelsForChannelPickerQuery__
 *
 * To run a query within a React component, call `useChannelsForChannelPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsForChannelPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsForChannelPickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useChannelsForChannelPickerQuery(baseOptions?: Apollo.QueryHookOptions<TChannelsForChannelPickerQuery, TChannelsForChannelPickerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TChannelsForChannelPickerQuery, TChannelsForChannelPickerQueryVariables>(ChannelsForChannelPickerDocument, options);
      }
export function useChannelsForChannelPickerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TChannelsForChannelPickerQuery, TChannelsForChannelPickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TChannelsForChannelPickerQuery, TChannelsForChannelPickerQueryVariables>(ChannelsForChannelPickerDocument, options);
        }
export type ChannelsForChannelPickerQueryHookResult = ReturnType<typeof useChannelsForChannelPickerQuery>;
export type ChannelsForChannelPickerLazyQueryHookResult = ReturnType<typeof useChannelsForChannelPickerLazyQuery>;
export type ChannelsForChannelPickerQueryResult = Apollo.QueryResult<TChannelsForChannelPickerQuery, TChannelsForChannelPickerQueryVariables>;