import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, GridLayout, imageTypes, MetadataListLayout } from 'components';
import { COLLECTION_DISPLAY_TYPES } from 'utils/constants';
import RegularItem from 'components/VideoItems/RegularItem';
import { useAnalytics } from 'components/Tracking';
import { viewableShape } from 'components/VideoItems/commonPropTypes';
import createComponent from 'styles/fela/createComponent';
import Spinner from 'components/Spinner/Spinner';
import InfiniteScroll from 'react-infinite-scroll-component';

const SearchResultsContainer = createComponent(() => ({
  overflowAnchor: 'none',
}), Box);

function SearchResultSection(props) {
  const analytics = useAnalytics();
  const {
    items,
    displayType,
    query,
    visible,
    hasMore,
    loadMore,
    loading,
  } = props;
  const gridRef = useRef(null);

  const trackClick = useCallback((viewableId) => {
    analytics.onClick({
      component: 'Search',
      clickType: 'asset',
      eventName: 'click_asset',
      element: 'asset',
      viewableId,
      searchString: query,
    });
  }, [query]);

  const hideChannelLogo = useSelector(state => !!state.settings.features.hideThumbnailChannelLogo);

  const initialLoad = useRef(true);

  useEffect(() => {
    if (displayType === COLLECTION_DISPLAY_TYPES.grid && gridRef.current
        && loadMore && hasMore && !loading && initialLoad.current) {
      const gridChildren = gridRef.current.children;
      const lastGridItem = gridChildren[gridChildren.length - 1];
      const lastGridItemTopPosition = lastGridItem.getBoundingClientRect().top;
      const { clientHeight } = document.documentElement;
      if (lastGridItemTopPosition < clientHeight) {
        loadMore();
      }
    }
  }, [items.length]);

  const onScroll = useCallback(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    }
  }, []);

  if (!visible || !items?.length) {
    return null;
  }

  return (
    <SearchResultsContainer mb="large">
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={items.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<Box column justifyContent="center" pt="xxxlarge" pb="xxxlarge"><Spinner size={3} /></Box>}
        onScroll={onScroll}
      >
        {displayType === COLLECTION_DISPLAY_TYPES.grid && (
          <GridLayout
            innerRef={gridRef}
            items={items}
            onClick={trackClick}
            ItemComponent={RegularItem}
            itemWidth={imageTypes.poster.width}
            hideChannelLogo={hideChannelLogo}
          />
        )}

        {displayType === COLLECTION_DISPLAY_TYPES.list && (
          <>
            <MetadataListLayout
              items={items}
              onClick={trackClick}
              ItemComponent={RegularItem}
              hideChannelLogo={hideChannelLogo}
            />
            {loadMore}
          </>
        )}
      </InfiniteScroll>
    </SearchResultsContainer>
  );
}

SearchResultSection.propTypes = {
  items: PropTypes.arrayOf(viewableShape).isRequired,
  displayType: PropTypes.string.isRequired,
  query: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loading: PropTypes.bool,
};

export default React.memo(SearchResultSection);
