import { ValueOf } from 'types';

export const OS_TYPES = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  WINDOWS_PHONE: 'WINDOWS_PHONE',
  WINDOWS: 'WINDOWS',
  MAC: 'MAC',
  LINUX: 'LINUX',
  OTHER: 'OTHER',
};
export type OSType = ValueOf<typeof OS_TYPES>;

export const BROWSER_TYPES = {
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  IE: 'IE',
  EDGE: 'Edge',
  SAFARI: 'Safari',
  MOBILE_SAFARI: 'Mobile Safari',
  OPERA: 'Opera',
  OTHER: 'Other',
};
export type BrowserType = ValueOf<typeof BROWSER_TYPES>;

export const TRIAL_PERIOD = {
  DAYS: 'DAYS',
  SECONDS: 'SECONDS',
};
export type TrialPeriod = ValueOf<typeof TRIAL_PERIOD>;

export const RECURRING_PERIOD = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};
export type RecurringPeriod = ValueOf<typeof RECURRING_PERIOD>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum VIEWABLE_TYPES {
  Movie = 'Movie',
  Episode = 'Episode',
  Program = 'Program',
  Show = 'Show',
  Channel = 'Channel',
  Trailer = 'Trailer',
  ATestViewable = 'ATestViewable',
}

export const CONTENT_LIST_SUPPORTED_VIEWABLE_TYPES = [
  VIEWABLE_TYPES.Movie,
  VIEWABLE_TYPES.Episode,
  VIEWABLE_TYPES.Program,
  VIEWABLE_TYPES.Show,
  VIEWABLE_TYPES.Channel,
];

export const BLOCK_INTERFACE_TYPES = {
  BookmarksCollection: 'BookmarksCollection',
  ContinueWatchingCollection: 'ContinueWatchingCollection',
  EntitledContentCollection: 'EntitledContentCollection',
  FavouriteChannelsCollection: 'FavouriteChannelsCollection',
  FeaturedCarouselCollection: 'FeaturedCarouselCollection',
  FeaturedCollection: 'FeaturedCollection',
  LinkCollection: 'LinkCollection',
  LiveChannelsCollection: 'LiveChannelsCollection',
  PosterCollection: 'PosterCollection',
  PromoBlock: 'PromoBlock',
  SixteenNineCollection: 'SixteenNineCollection',
  ATestBlock: 'ATestBlock',
};
export type BlockInterfaceType = ValueOf<typeof BLOCK_INTERFACE_TYPES>;

export const CONTENT_LIST_SUPPORTED_BLOCK_TYPES = [
  BLOCK_INTERFACE_TYPES.BookmarksCollection,
  BLOCK_INTERFACE_TYPES.ContinueWatchingCollection,
  BLOCK_INTERFACE_TYPES.EntitledContentCollection,
  BLOCK_INTERFACE_TYPES.FavouriteChannelsCollection,
  BLOCK_INTERFACE_TYPES.FeaturedCarouselCollection,
  BLOCK_INTERFACE_TYPES.FeaturedCollection,
  BLOCK_INTERFACE_TYPES.LinkCollection,
  BLOCK_INTERFACE_TYPES.LiveChannelsCollection,
  BLOCK_INTERFACE_TYPES.PosterCollection,
  BLOCK_INTERFACE_TYPES.PromoBlock,
  BLOCK_INTERFACE_TYPES.SixteenNineCollection,
];

export const LINK_INTERFACE_TYPES = {
  CollectionLink: 'CollectionLink',
  ViewLink: 'ViewLink',
  ATestLink: 'ATestLink',
};

export const LINK_INTERFACE_SUPPORTED_TYPES = [
  LINK_INTERFACE_TYPES.CollectionLink,
  LINK_INTERFACE_TYPES.ViewLink,
];

// The strings in this enum becomes part of the url, hence lower-case.
export const WatchContext = {
  VOD: 'vod',
  PLAY: 'play',
  FAVORITE: 'favorite',
  POPULAR: 'popular',
  FEATURED: 'featured',
  RECOMMENDED: 'recommended',
};

export const PLAYABLE_KINDS = {
  VOD: 'VOD',
  CHANNEL: 'CHANNEL',
  BROADCAST: 'BROADCAST',
  LIVE_EVENT: 'LIVE_EVENT',
};

export const OFFER_TYPES = {
  DefaultType: 'DefaultType',
  PassType: 'PassType',
  ThirdPartyType: 'ThirdPartyType',
  BuyType: 'BuyType',
  RentType: 'RentType',
  SubscribeType: 'SubscribeType',
} as const;
export type OfferType = ValueOf<typeof OFFER_TYPES>;

// Used for variables when building GraphQL queries
export const OFFER_TYPE_TO_QUERY_TYPE: Record<OfferType, string> = {
  DefaultType: 'DEFAULT',
  PassType: 'PASS',
  ThirdPartyType: 'THIRDPARTY',
  BuyType: 'BUY',
  RentType: 'RENT',
  SubscribeType: 'SUBSCRIBE',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ENTITLEMENT_TYPES {
  DEFAULT = 'EntitlementDefaultType',
  PASS = 'EntitlementPassType',
  THIRDPARTY = 'EntitlementThirdPartyType',
  BUY = 'EntitlementBuyType',
  RENT = 'EntitlementRentType',
  SUBSCRIBE = 'EntitlementSubscribeType',
}

// defined in https://magine.atlassian.net/browse/MDM-9357
export const PURCHASE_FLOW_OFFER_TYPES = [
  OFFER_TYPES.SubscribeType,
  OFFER_TYPES.PassType,
];

export const PAYMENT_PROVIDERS = {
  ADYEN: 'adyen',
  ADYEN_DROPIN: 'adyen-dropin',
  STRIPE: 'stripe',
};

/*  https://docs.adyen.com/developers/api-reference/hosted-payment-pages-api#hpppaymentresponsefields
    AUTHORISED: The payment authorisation was successfully completed.
    REFUSED: The payment was refused. Payment authorisation was unsuccessful.
    CANCELLED: The payment was cancelled by the shopper before completion,
    or the shopper returned to the merchant's site before completing the transaction.
    PENDING: It is not possible to obtain the final status of the payment.
    This can happen if the systems providing final status information for the payment are
    unavailable, or if the shopper needs to take further action to complete the payment.
    ERROR: An error occurred during the payment processing.
*/
export const ADYEN_RESULT_TYPES = {
  AUTHORISED: 'AUTHORISED',
  REFUSED: 'REFUSED',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
};

export const ADYEN_DROPIN_RESULT_TYPES = {
  AUTHORISED: 'AUTHORISED',
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  PRESENT_TO_SHOPPER: 'PRESENTTOSHOPPER',
  REFUSED: 'REFUSED',
  RECEIVED: 'RECEIVED',
};

export const ADYEN_PATHS = {
  REDIRECT_TO_ADYEN_PATH: '/adyen-redirect',
  ADYEN_RESULT_REDIRECT_PATH: '/adyen-checkout-redirect',
  DROPIN_REDIRECT_PATH: '/adyen-dropin-redirect',
};

export const STRIPE_STATUS = {
  INITIAL: 'initial',
  SUCCESS: 'success',
};

export const CHROMECAST_STATES = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  UNAVAILABLE: 'unavailable',
  DISCONNECTED: 'disconnected',
};

export const TRANSACTION_STATUS = {
  INITIAL: 'INITIAL',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
};
export type TransactionStatus = ValueOf<typeof TRANSACTION_STATUS>;

export const PAYMENT_METHODS = {
  PAYPAL: 'paypal',
  CREDITCARD: 'credit_card',
  GOOGLEPAY: 'googlepay',
  APPLEPAY: 'applepay',
};

export const EntitlementStatus = {
  active: 'active',
  cancelled: 'cancelled',
  expired: 'expired',
};

export const WATCHLIST_TYPES = [
  VIEWABLE_TYPES.Movie,
  VIEWABLE_TYPES.Show,
];

export const LINK_TYPES = {
  url: 'url',
  email: 'email',
  phone: 'phone',
};
export type LinkType = ValueOf<typeof LINK_TYPES>;

export const PINCODE_ERRORS = {
  REQUEST_THROTTLED: 'REQUEST_THROTTLED',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  USER_CAN_NOT_EDIT: 'USER_CAN_NOT_EDIT',
  WRONG_PIN_CODE: 'WRONG_PIN_CODE',
};

export const INPUT_FIELD_TYPE = {
  default: 'default',
  email: 'email',
  yyyy: 'yyyy',
  number: 'number',
  picker: 'picker',
  checkbox: 'checkbox',
  emailConfirmation: 'emailConfirmation',
  passwordConfirmation: 'passwordConfirmation',
  date: 'date',
};

export const MENU_ITEM_TYPES = {
  contentList: 'contentList',
  epg: 'epg',
  search: 'search',
  link: 'link',
  offlineContentList: 'offlineContentList',
  settings: 'settings',
  subscriptionMagine: 'subscription-magine',
};

export const MENU_LINK_TARGET_TYPES = {
  self: 'self',
  blank: 'blank',
  webview: 'webview',
};

export const COLLECTION_SIZE = {
  large: 'large',
  small: 'small',
  sixteenNine: 'sixteen-nine',
};
export type CollectionSizeType = ValueOf<typeof COLLECTION_SIZE>;

export const COLLECTION_DISPLAY_TYPES = {
  grid: 'grid',
  list: 'list',
};
export type CollectionDisplayType = ValueOf<typeof COLLECTION_DISPLAY_TYPES>;

export const SEARCH_FILTER_KINDS = {
  all: 'all',
  channels: 'channels',
  shows: 'shows',
  movies: 'movies',
  programs: 'programs',
};
export type SearchFilterKind = ValueOf<typeof SEARCH_FILTER_KINDS>;

export const LAYOUT_OBJECT_TYPES = {
  channelLogo: 'channelLogo',
  textString: 'textString',
  textMultiFields: 'textMultiFields',
  textKeyAndList: 'textKeyAndList',
  buttonCta: 'buttonCta',
};

export const FIELD_TO_RENDER_TYPES = {
  title: 'title',
  synopsis: 'synopsis',
  kind: 'kind',
  genres: 'genres',
  productionYear: 'productionYear',
  rating: 'rating',
  duration: 'duration',
  directors: 'directors',
  cast: 'cast',
  description: 'description', // to go with Promo Shape.
  customTags1: 'customTags1',
  customTags2: 'customTags2',
  customTags3: 'customTags3',
  customTags4: 'customTags4',
  customTags5: 'customTags5',
  customTags6: 'customTags6',
  customTags7: 'customTags7',
  customTags8: 'customTags8',
  customTags9: 'customTags9',
  customTags10: 'customTags10',
  collectionTitle: 'collectionTitle',
  collectionDescription: 'collectionDescription',
  collectionCtaLabel: 'collectionCtaLabel',
};

export const FIELD_TO_TAG = {
  [FIELD_TO_RENDER_TYPES.directors]: 'DetailViewMetadataDirectors',
  [FIELD_TO_RENDER_TYPES.cast]: 'DetailViewMetadataCast',
  [FIELD_TO_RENDER_TYPES.genres]: 'DetailViewMetadataGenres',
  [FIELD_TO_RENDER_TYPES.customTags1]: 'DetailViewMetadataCustomTags1',
  [FIELD_TO_RENDER_TYPES.customTags2]: 'DetailViewMetadataCustomTags2',
  [FIELD_TO_RENDER_TYPES.customTags3]: 'DetailViewMetadataCustomTags3',
  [FIELD_TO_RENDER_TYPES.customTags4]: 'DetailViewMetadataCustomTags4',
  [FIELD_TO_RENDER_TYPES.customTags5]: 'DetailViewMetadataCustomTags5',
  [FIELD_TO_RENDER_TYPES.customTags6]: 'DetailViewMetadataCustomTags6',
  [FIELD_TO_RENDER_TYPES.customTags7]: 'DetailViewMetadataCustomTags7',
  [FIELD_TO_RENDER_TYPES.customTags8]: 'DetailViewMetadataCustomTags8',
  [FIELD_TO_RENDER_TYPES.customTags9]: 'DetailViewMetadataCustomTags9',
  [FIELD_TO_RENDER_TYPES.customTags10]: 'DetailViewMetadataCustomTags10',
};

// predecessor of ScreenSizeStyles enum
export const SCREEN_SIZE_STYLES = {
  bigScreenStyle: 'bigScreenStyle',
  mediumScreenStyle: 'mediumScreenStyle',
  smallScreenStyle: 'smallScreenStyle',
};

export const ANALYTICS_ACTION_SOURCES = {
  USER_ACTION: 'USER_ACTION',
  APP_ACTION: 'APP_ACTION',
  APP_ERROR: 'APP_ERROR',
};

export const ANALYTICS_ACTIONS = {
  PAGE_VIEW: 'page_view',
  CLICK: 'click',
  BUY: 'buy',
  CANCEL: 'cancel',
  VISIBLE: 'visible',
};

export const ANALYTICS_EVENTS = {
  OFFER: 'offer',
};

export const COOKIES = {
  CREATE_PASS_EMAIL: 'create_pass_email',
};

export const DATE_FORMAT = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const PLATFORMS = {
  VIZIO: 'vizio',
  WEBOS: 'webos',
  TIZEN: 'tizen',
  BROWSER: 'browser',
  MOVISTAR: 'movistar',
  IOS: 'ios',
  ANDROID: 'android',
};

export const PLATFORMS_NAME = {
  VIZIO: 'Vizio',
  WEBOS: 'webOS',
  TIZEN: 'Tizen',
  BROWSER: 'Browser',
  MOVISTAR: 'Movistar+',
  IOS: 'iOS',
  ANDROID: 'Android',
};

export const SUBSCRIBE_KIND = {
  DOWNGRADE: 'DOWNGRADE',
  NEW: 'NEW',
  UPGRADE: 'UPGRADE',
};

export const TRANSACTION_TYPE = {
  SUBSCRIPTION_UPGRADE: 'subscription_upgrade',
};

// `xs` is 0 up to `sm`;
export const BREAKPOINTS = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export type BreakpointNames = keyof typeof BREAKPOINTS;

export const BUTTON_HEIGHT = '3rem';
export const BUTTON_HEIGHT_BIG = '3.5rem';

export const INFO_DELIMITER = ' | ';

export const BASE_MARGIN = {
  small: '1.5rem',
  medium: '2.5rem',
  large: '5.0rem',
};

export const EXIT_FULL_WINDOW_AREA = {
  ICON: 'icon',
  TITLE: 'title',
  NEXT_BACK_ICON: 'nextBackIcon', // icon from Next Recommendation
};

export const TRACK_AREA = {
  playBtn: 'play-btn',
  backBtn: 'back-btn',
  infoBtn: 'info-btn',
  triggerArea: 'trigger-area',
  img: 'img',
  watchlistBtn: 'watchlist-btn',
  continueWatchingBtn: 'continue-watching-btn',
  metadata: 'metadata',
  offerBtn: 'offer-btn',
  miniPlayer: 'mini-player',
};

export const DATA_ID = {
  hoverStateLink: 'hover-state-link',
  hoverState: 'hover-state',
  carouselLink : 'carousel-link',
  featuredLink : 'featured-link',
  recommendationBtn: 'recommendation-btn',
  recommendationLink: 'recommendation-link',
};

export const DATA_VALUE = {
  triggerAreaInfo: 'trigger-area/info',
  imgAutoplay: 'img/autoplay',
  imgInfo: 'img/info',
  playBtnAutoplay: 'play-btn/autoplay',
  infoBtnInfo: 'info-btn/info',
  metadataInfo: 'metadata/info',
  backBtn: 'back-btn',
};
