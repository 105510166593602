import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatch } from 'reducers';
import * as cookieActions from 'actions/cookie';
import { usePrevious } from 'utils/hooks';

export const COOKIE_TOOL_TYPES = {
  COOKIEBOT: 'cookiebot',
};

const DEFAULT_GOOGLE_CONSENT_VALUE = {
  ad_personalization: 'denied',
  ad_storage: 'denied',
  ad_user_data: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'granted',
  wait_for_update: 500,
};

export const getCookieConsent = (cookieToolType) => {
  let cookieConsent = {};
  if (cookieToolType === COOKIE_TOOL_TYPES.COOKIEBOT) {
    cookieConsent = window.Cookiebot?.consent;
  }
  return cookieConsent;
};

const CookieTool = (props) => {
  const { children } = props;

  const {
    cookieTool,
    googleTagManagerId,
    cookieConsent,
  } = useSelector(({ settings, cookie }) => ({
    cookieTool: settings.cookieTool,
    googleTagManagerId: settings.features.googleTagManager?.id,
    cookieConsent: cookie.cookieConsent,
  }), shallowEqual);
  const dispatch = useDispatch();
  const previousCookieConsent = usePrevious(cookieConsent);

  useEffect(() => {

    const updateCookieConsent = (consent) => {
      if (!consent) return;
      const { necessary, marketing, preferences, statistics } = consent;
      dispatch(cookieActions.setCookieConsent({ necessary, marketing, preferences, statistics }));
    };

    if (!cookieTool) {
      updateCookieConsent({
        necessary: true, marketing: true, preferences: true, statistics: true,
      });
    } else if (cookieTool?.type === COOKIE_TOOL_TYPES.COOKIEBOT) {
      const cookieBotOnAccept = () => {
        updateCookieConsent(getCookieConsent(cookieTool?.type));
      };
      const cookieBotOnDecline = () => {
        updateCookieConsent(getCookieConsent(cookieTool?.type));
      };

      updateCookieConsent(getCookieConsent(cookieTool?.type));
      window.addEventListener('CookiebotOnAccept', cookieBotOnAccept, false);
      window.addEventListener('CookiebotOnDecline', cookieBotOnDecline, false);

      return () => {
        window.removeEventListener('CookiebotOnAccept', cookieBotOnAccept, false);
        window.removeEventListener('CookiebotOnDecline', cookieBotOnDecline, false);
      };
    }
    return () => null;
  }, [cookieTool]);

  useEffect(() => {
    if (!cookieTool) return;

    if ((previousCookieConsent.preferences && !cookieConsent.preferences) ||
      (previousCookieConsent.statistics && !cookieConsent.statistics) ||
      (previousCookieConsent.marketing && !cookieConsent.marketing)
    ) {
      // if cookie consent is revoked, we need to reload page otherwise cookie may still be created
      window.location.reload();
    }
  }, [cookieTool, cookieConsent]);

  if (!cookieTool) return (
    <>
    {children}
    </>
  );

  const script = cookieTool.script ? atob(cookieTool.script) : '';

  const googleConsentDefaultValue = JSON.stringify({
    ...DEFAULT_GOOGLE_CONSENT_VALUE,
    ...cookieTool.param['google-consent-default-value'],
  }, null, 2);

  return (
    <>
      {cookieTool.type === COOKIE_TOOL_TYPES.COOKIEBOT && googleTagManagerId && (
        <Helmet>
          {/* Google Consent Mode */}
          <script data-cookieconsent="ignore">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments)
            }
            gtag("consent", "default", ${googleConsentDefaultValue});
            gtag("set", "ads_data_redaction", true);
            gtag("set", "url_passthrough", true);
            `}
          </script>
          {/* End Google Consent Mode */}
        </Helmet>
      )}
      {cookieTool.type !== COOKIE_TOOL_TYPES.COOKIEBOT && script && (
        <Helmet>
          <script>
            {script}
          </script>
        </Helmet>
      )}
      {children}
      {cookieTool.type === COOKIE_TOOL_TYPES.COOKIEBOT && (
        <Helmet>
          {/* Cookiebot CMP */}
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid={cookieTool.param['data-cbid']}
            type="text/javascript"
          ></script>
          {/* End Cookiebot CMP */}
        </Helmet>
      )}
    </>
  );
};

CookieTool.propTypes = {
  children: PropTypes.element.isRequired,
};
export default CookieTool;
