import createComponent from 'styles/fela/createComponent';
import {
  withSpinning,
} from 'styles/fela/mixins';
import CircleIcon from 'components/Icons/CircleIcon';
import PlusIcon from 'components/Icons/PlusIcon';
import CheckIcon from 'components/Icons/CheckIcon';
import { BUTTON_HEIGHT, BUTTON_HEIGHT_BIG } from 'utils/constants';

export const Container = createComponent(({ theme, inProgress, short }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontSize: theme.fontSize.normal,
  letterSpacing: '0.5px',
  userSelect: 'none',
  extend: [
    {
      condition: inProgress,
      style: {
        cursor: 'wait',
      },
    },
    {
      condition: short,
      style: {
        display: 'inline-block',
        paddingRight: theme.margin.small,
      },
    },
  ],
  ':hover:not([disabled]) svg circle': {
    fill: theme.color.whiteButtonBackgroundHover,
  },
}), 'div', ['inProgress', 'short']);

export const IconContainer = createComponent(({ big }) => ({
  position: 'relative',
  width: big ? BUTTON_HEIGHT_BIG : BUTTON_HEIGHT,
  height: big ? BUTTON_HEIGHT_BIG : BUTTON_HEIGHT,
  overflow: 'hidden',
}), 'div', ['big']);

export const StyledCircleIcon = createComponent(({ theme }) => ({
  color: 'transparent',
  width: '100%',
  height: '100%',
  overflow: 'visible',
  fill: theme.color.whiteButtonBackground,
  '& circle': {
    transition: 'fill 0.2s ease-in-out',
  },
}), CircleIcon, []);

export const StyledPlusIcon = createComponent(({ inMyList, inProgress }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  color: 'white',
  width: '100%',
  height: '100%',
  overflow: 'visible',
  extend: [
    {
      condition: inProgress,
      style: withSpinning(500, !inMyList),
    },
  ],
}), PlusIcon, ['inProgress', 'inMyList']);

export const StyledCheckIconContainer = createComponent(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '55%',
  height: '55%',
}));

export const StyledCheckIcon = createComponent(({ inMyList, inProgress }) => ({
  color: 'white',
  width: '100%',
  height: '100%',
  overflow: 'visible',
  extend: [
    {
      condition: inProgress,
      style: withSpinning(250, !inMyList),
    },
  ],
}), CheckIcon, ['inProgress', 'inMyList']);
