import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import logger from 'utils/logger';
import * as packsActions from 'actions/packs';
import * as telemetryActions from 'actions/telemetry';
import { useAnalytics } from 'components/Tracking';
import { entitledPackShape } from 'reducers/packs';
import { RequestStates } from 'utils/helpers';
import { DATE_FORMAT } from 'utils/constants';
import {
  Modal,
  ModalContainer,
  PrimaryButton,
  SecondaryButton,
  Text,
  Heading,
  TextDate,
  Box,
  ErrorMessage,
  Hr,
} from 'components';
import Survey from './Survey';
import WinbackModal from 'views/Settings/Subscription/WinbackModal';

const getWinback = (winbacks, pack) => {
  const winback = winbacks.find(w => w.offerId === pack.id);
  if (winback) {
    winback.price.recurringPeriod = pack.recurringPeriod;
    winback.startDate = pack.nextBillingDate;
  }
  return winback;
};

export const WINBACK_DISMISS_TYPE = {
  CANCEL: 'CANCEL',
  CLOSE: 'CLOSE',
};

export default function CancelPackModal({ pack, onDismiss }) {
  const dispatch = useDispatch();
  const survey = useSelector(state => state.settings.features.surveySettings?.find(item => item.surveyType === 'cancelSubscription'));
  const analytics = useAnalytics();
  const [cancelState, setCancelState] = useState(RequestStates.initial);
  const [answer, setAnswer] = useState(null);

  const {
    winbacks,
  } = useSelector(({ packs }) => ({
    winbacks: packs.winbacks,
  }), shallowEqual);

  const winback = getWinback(winbacks, pack);
  const [showWinbackModal, setShowWinbackModal] = useState(false);

  async function cancelSubscription() {
    setCancelState(RequestStates.pending);

    try {
      analytics.onCancelSubscription(pack.id);
      await Promise.all([
        dispatch(packsActions.cancelPackage(pack.id)),

        answer && dispatch(telemetryActions.sendFeedback(
          survey.surveyId,
          answer.key,
          answer.label,
          answer.freeText,
        )),
      ]);
      setCancelState(RequestStates.success);
      onDismiss();
    } catch (e) {
      logger.error(e);
      setCancelState(RequestStates.failed);
    }
  }

  async function onCancelSubscriptionClick() {
    if (winback) {
      setShowWinbackModal(true);
    } else {
      await cancelSubscription();
    }
  }

  const onWinbackModelDismiss = async (type) => {
    switch (type) {
      case WINBACK_DISMISS_TYPE.CANCEL: {
        await cancelSubscription();
        break;
      }
      case WINBACK_DISMISS_TYPE.CLOSE: {
        onDismiss();
        break;
      }
      default: {
        setShowWinbackModal(false);
        break;
      }
    }
  };

  return (<>
    {showWinbackModal && (
      <WinbackModal
        winback={winback}
        onDismiss={onWinbackModelDismiss}
      />
    )}
    <Modal onDismiss={onDismiss} hide={showWinbackModal}>
      <ModalContainer withWidth="medium">
        <Heading
          id="cancelPackageModal.title"
          fontSize="medium"
          isHtml
        />

        <Box
          mt="medium"
          mb="large"
        >
          <Hr />
        </Box>

        <Box my="medium">
          {pack.nextBillingDate && (
            <Text
              id="cancelPackageModal.activeUntil"
              values={{
                date: <TextDate key="nextBillingDate" value={pack.nextBillingDate} options={DATE_FORMAT} />,
              }}
            />
          )}
        </Box>

        {survey && (
          <Box
            my="medium"
            mx="xlarge"
          >
            <Survey
              answers={survey.answers}
              selectedAnswer={answer}
              onSelected={setAnswer}
            />
          </Box>
        )}

        <Box
          alignItems="center"
          spaceBetween
          wrap
        >
          <Box mt="medium" xs-mx="auto">
            <PrimaryButton variant="brand" minWidth="15rem" onClick={onDismiss}>
              <Text id="cancelPackageModal.abortButton" />
            </PrimaryButton>
          </Box>

          <Box mt="medium" xs-mx="auto">
            <SecondaryButton
              variant="white"
              minWidth="15rem"
              onClick={onCancelSubscriptionClick}
              showSpinner={cancelState === RequestStates.pending}
            >
              <Text id="cancelSubscription" />
            </SecondaryButton>
          </Box>
        </Box>

        {cancelState === RequestStates.failed && (
          <Box my="medium">
            <ErrorMessage id="error" />
          </Box>
        )}
      </ModalContainer>
    </Modal>
  </>);
}
CancelPackModal.propTypes = {
  pack: entitledPackShape.isRequired,
  onDismiss: PropTypes.func.isRequired,
};
