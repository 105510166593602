import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal/Modal';
import ModalContainer from 'components/Modal/ModalContainer';
import { Box } from 'components/StyledSystem/Box';
import PulsingButton from 'components/Button/PulsingButton';
import Text from 'components/StyledSystem/Text';
import ErrorMessage from 'components/StyledSystem/ErrorMessage';
import PrimaryButton from 'components/Button/PrimaryButton';
import {
  ModalHeading,
  ModalSubHeading,
} from 'components/Modal/ModalStyles';
import { PINCODE_ERRORS } from 'utils/constants';
import logger from 'utils/logger';
import createComponent from 'styles/fela/createComponent';
import PinInputs from './PinInputs';
import ResetPin from './ResetPin';

const BUTTON_WIDTH = '177px';

const ErrorContainer = createComponent(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '59px',
  padding: '14px',
}));

const ParentalControlModal = (props) => {
  const {
    onClose,
    submitPinCode,
    descriptionTextKey = 'parentalControl.enterPin',
    error = '',
    pending = false,
  } = props;

  const {
    email,
  } = useSelector(({ user }) => ({
    email: user.contactEmail,
  }));

  const [isPinReadyForSubmit, setIsPinReadyForSubmit] = useState(false);
  const [isResetPinExpanded, setIsResetPinExpanded] = useState(false);
  const [showResetPinConfirmation, setShowResetPinConfirmation] = useState(false);
  const [disableErrorAfterPinReset, setDisableErrorAfterPinReset] = useState(false);

  const pinInputsRef = useRef();

  const triggerSubmitPin = () => {
    pinInputsRef.current.submit();
  };

  const onChangePinReadyForSubmit = (value) => {
    setIsPinReadyForSubmit(value);
  };

  const expandResetPin = () => {
    setIsResetPinExpanded(true);
  };

  const collapseResetPin = () => {
    setIsResetPinExpanded(false);
  };

  const openResetPinConfirmation = () => {
    setShowResetPinConfirmation(true);
  };

  const closeResetPinConfirmation = () => {
    setIsResetPinExpanded(false);
    setShowResetPinConfirmation(false);
    setDisableErrorAfterPinReset(true);
  };

  const renderErrorMessage = () => {
    let errorMessageId;
    switch (error) {
      case PINCODE_ERRORS.WRONG_PIN_CODE: {
        errorMessageId = 'parentalControl.wrongPin';
        break;
      }
      case PINCODE_ERRORS.INTERNAL_SERVER_ERROR: {
        errorMessageId = 'parentalControlActivation.serverError';
        break;
      }
      case PINCODE_ERRORS.USER_CAN_NOT_EDIT: {
        errorMessageId = 'parentalControlActivation.userCantDisable';
        break;
      }
      case PINCODE_ERRORS.REQUEST_THROTTLED: {
        errorMessageId = 'parentalControlActivation.requestThrottled';
        break;
      }
      default: {
        logger.warn('Modal received unknown code for parental control related error', error);
        errorMessageId = 'parentalControlActivation.serverError';
      }
    }

    return (
      <Text id={errorMessageId} />
    );
  };

  const handleSubmitPinCode = (pinCode) => {
    setDisableErrorAfterPinReset(false);
    submitPinCode(pinCode);
  };

  const renderResetPin = () => (
    <Box mt="xlarge" mb="small">
      { !isResetPinExpanded && (
        <PulsingButton
          link
          onClick={expandResetPin}
          bold
        >
          <Text id="appSettings.resetParentalControl" />
        </PulsingButton>
      )}
      { !!isResetPinExpanded && (
        <ResetPin
          onSuccess={openResetPinConfirmation}
          onClose={collapseResetPin}
          withButtonWidth={BUTTON_WIDTH}
          compact
        />
      )}
    </Box>
  );

  const renderPinCode = () => {
    const showPinInputError = !disableErrorAfterPinReset && !!error;

    return (
      <>
        <Box row mt="xlarge">
          <PinInputs
            ref={pinInputsRef}
            onPinEntered={handleSubmitPinCode}
            onChangePinReadyForSubmit={onChangePinReadyForSubmit}
          />
        </Box>

        <ErrorContainer>
          {showPinInputError && (
            <ErrorMessage align="center">
              {renderErrorMessage()}
            </ErrorMessage>
          )}
        </ErrorContainer>

        <Box>
          <PrimaryButton
            onClick={triggerSubmitPin}
            variant="brand"
            // Match exactly the width of the pin inputs
            withWidth={BUTTON_WIDTH}
            disabled={!isPinReadyForSubmit}
            showSpinner={pending}
          >
            <Text id="appSettings.disableParentalControlModalBtnOk" />
          </PrimaryButton>
        </Box>
        {renderResetPin()}
      </>
    );
  };

  const renderResetPinConfirmation = () => (
    <>
      <Box mt="xxlarge">
        <Text
          bold
          align="center"
          id="appSettings.sendNewPinCodeModalSuccessModalInfo"
          values={{
            email,
          }}
        />
      </Box>

      <Box
        mt="xxxlarge"
        mb="xlarge"
      >
        <PrimaryButton
          variant="brand"
          onClick={closeResetPinConfirmation}
        >
          <Text id="appSettings.sendNewPinCodeModalBtnText" />
        </PrimaryButton>
      </Box>
    </>
  );

  return (
    <Modal onDismiss={onClose}>
      <ModalContainer
        onClickClose={onClose}
        withShadow
        withWidth="small"
      >
        <Box column>
          <Box mt="large" fullWidth>
            <Box mt="0.83rem" mb="0.7rem">
              <ModalHeading id="appSettings.disableParentalControlModalHeader" />
            </Box>

            { !showResetPinConfirmation && (
              <Box mb="fine">
                <ModalSubHeading id={descriptionTextKey} />
              </Box>
            )}
          </Box>
          { !showResetPinConfirmation && renderPinCode() }
          { !!showResetPinConfirmation && renderResetPinConfirmation() }
        </Box>
      </ModalContainer>
    </Modal>
  );
};

ParentalControlModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  submitPinCode: PropTypes.func.isRequired,
  descriptionTextKey: PropTypes.string,
  error: PropTypes.string,
  pending: PropTypes.bool,
};

export default ParentalControlModal;
