import PropTypes from 'prop-types';
import { channelShape } from 'utils/shapes';
import { getRecurringPeriod } from 'utils/helpers';
import {
  RECURRING_PERIOD,
  ENTITLEMENT_TYPES,
  EntitlementStatus,
} from 'utils/constants';
import { FETCH_ENTITLED_PACKS, FETCH_PACKS, PacksActions } from 'actions/packs';

export const entitledPackShape = PropTypes.shape({
  __typename: PropTypes.oneOf(Object.values(ENTITLEMENT_TYPES)).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  usps: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasTrial: PropTypes.bool.isRequired,
  trialLengthDays: PropTypes.number,
  netPrice: PropTypes.number,
  grossPrice: PropTypes.number,
  isFree: PropTypes.bool.isRequired,
  currency: PropTypes.string,
  nextBillingDate: PropTypes.instanceOf(Date),
  status: PropTypes.oneOf(Object.values(EntitlementStatus)).isRequired,
  endDate: PropTypes.instanceOf(Date),
  recurringPeriod: PropTypes.oneOf(Object.values(RECURRING_PERIOD)),
});

export const discountShape = PropTypes.shape({
  discount: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    market: PropTypes.string, // market might be undefined
    pack: PropTypes.string,

    discountCycles: PropTypes.number,
    bulletPoints: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  endDate: PropTypes.number,
});

export const packShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  usps: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string,
  isFree: PropTypes.bool.isRequired,
  hasTrial: PropTypes.bool.isRequired,
  trialDays: PropTypes.number,
  netPrice: PropTypes.number.isRequired,
  grossPrice: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  discount: discountShape,
  channels: PropTypes.arrayOf(channelShape).isRequired,
  topChannels: PropTypes.arrayOf(channelShape).isRequired,
  promoCode: PropTypes.string,
  recurringPeriod: PropTypes.oneOf(Object.values(RECURRING_PERIOD)),
  bypassCheckout: PropTypes.bool,
});

export type PacksReducerState = {
  offers: any[],
  promoCode?: string,
  isPromoCodeValid: boolean,

  entitled: any[],
  discounts: any[],
  winbacks: any[],
};
const defaultState: PacksReducerState = {
  offers: [],
  promoCode: undefined,
  isPromoCodeValid: true,

  entitled: [],
  discounts: [],
  winbacks: [],
};

export function packsReducer(state = defaultState, action: PacksActions) {
  switch (action.type) {
    case FETCH_PACKS: {
      const offers = action.packs.map((node: any) => ({
        id: node.id,
        name: node.title,
        description: node.decorationText,
        usps: node.usps || [],
        image: node.image,
        isFree: node.priceInCents === 0,
        hasTrial: !!node.trialPeriod,
        recurringPeriod: getRecurringPeriod(node),
        currency: node.currency,
        channels: [],
        topChannels: [],

        promoCode: node.promoCode,
        netPrice: node.offerPrice ? node.offerPrice.reducedPrice.amount : node.priceInCents,
        grossPrice: node.offerPrice ? node.offerPrice.price.amount : node.priceInCents,
        discount: node.offerPrice && {
          discount: {
            name: node.promoCode,
            pack: node.id,
            bulletPoints: [],
          },
          amount: node.offerPrice.price.amount - node.offerPrice.reducedPrice.amount,
        },
        bypassCheckout: node.bypassCheckout,
      }));

      return {
        ...state,
        offers,
        promoCode: action.promoCode,
        isPromoCodeValid: action.isPromoCodeValid,
      };
    }

    case FETCH_ENTITLED_PACKS: {
      return {
        ...state,
        entitled: action.data.packs,
        discounts: action.data.discounts,
        winbacks: action.data.winbacks,
      };
    }

    default:
      return state;
  }
}
