export const getWinbackIdProp = offerId => `winback_${offerId}`;

const getWinbackQueryPart = offerId => `
  ${getWinbackIdProp(offerId)}: promoCodeWinbackPreview(offerId: "${offerId}") {
    id
    name
    end {
      cycles
      date
    }
    images {
      url
      isDefault
      kind
    }
    message
    price {
      netPrice
      grossPrice
      currency
    }
    url
    usps
  }
`;

export const buildGetWinbacksQuery = offerIds => `
query getWinbacks {
  viewer {
    id: magineId
    ${offerIds.map(getWinbackQueryPart).join('\n')}
  }
}
`;
