import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'router';
import { ClickTracker } from 'components/Tracking';
import { Text } from 'components';
import { MENU_ITEM_TYPES, MENU_LINK_TARGET_TYPES } from 'utils/constants';
import { useI18n } from 'components/I18n';
import * as pageActions from 'actions/page';
import { resetScrollableOffset } from 'actions/scrollable';
import Search from './Search';
import { MenuContainer, MenuLink, Nav } from './Styles';
import SearchLink from './SearchLink';

const DEFAULT_MENU_ITEM_AVAILABILITY = ['logged-in', 'logged-out', 'open-service'];

function TopNavigation(props) {
  const {
    position,
    hideItemsForUnentitled,
    innerRef,
    isExpanded,
    isOffCanvas,
  } = props;

  const i18n = useI18n();

  const {
    isLoggedIn,
    isOpenServiceActive,
    isSearchEnabled,
    settingsMenu,
    navigation,
    location,
    page,
    collectionRefreshThreshold,
  } = useSelector(state => ({
    isLoggedIn: state.auth.isLoggedIn,
    isOpenServiceActive: state.country.isOpenServiceActive,
    isSearchEnabled: !!state.settings.searchMenu,
    settingsMenu: state.settings.settingsMenu,
    navigation: state.settings.navigation,
    location: state.router.location,
    page: state.page,
    collectionRefreshThreshold: state.settings.features.collectionRefreshThresholdInMin
      ? state.settings.features.collectionRefreshThresholdInMin * 60 * 1000
      : undefined,
  }), shallowEqual);
  const dispatch = useDispatch();

  const router = useRouter();

  let withSearch = !hideItemsForUnentitled
    && (isLoggedIn || isOpenServiceActive)
    && isSearchEnabled;
  let withSettings = !!settingsMenu;

  useEffect(() => {
    const { menu, defaultIndex } = navigation;
    const identifier = location.name === 'home'
      ? menu[defaultIndex].identifier
      : location.params.id;
    if (!page[identifier]) {
      dispatch(pageActions.UpdatePage({
        id: identifier,
        lastViewDate: new Date(),
        isExpired: false,
      }));
    }
  }, [location.name]);

  const handleContentListMenuClick = (item) => {
    const now = new Date();
    const isExpired = page[item.identifier] && !page[item.identifier].isExpired
    && collectionRefreshThreshold
      ? (now - page[item.identifier].lastViewDate) > collectionRefreshThreshold
      : false;
    if (isExpired) {
      dispatch(pageActions.UpdatePage({
        id: item.identifier,
        lastViewDate: new Date(),
        isExpired: true,
      }));
    }

    dispatch(resetScrollableOffset(item.identifier));
  };

  const renderMenuItems = () => {
    const { menu, defaultIndex } = navigation;
    const currentUrl = router.getUrl(location, false);

    return menu.map((item, i) => {
      const availability = item.available || DEFAULT_MENU_ITEM_AVAILABILITY;

      const isVisible = (availability.includes('logged-in') && isLoggedIn)
        || (availability.includes('open-service') && isOpenServiceActive)
        || (availability.includes('logged-out') && !isLoggedIn);

      if (!isVisible) {
        if (item.type === MENU_ITEM_TYPES.search) {
          // do not show search menu if it should not be visible
          withSearch = false;
        } else if (item.type === MENU_ITEM_TYPES.settings) {
          // do not show settings menu if it should not be visible
          withSettings = false;
        }
        return null;
      }

      if (i === defaultIndex) { // home
        return (
          <MenuLink
            key="home"
            to={{ name: 'home' }}
            data-content={item.title}
            active={location.name === 'home'}
            onClick={() => {
              if (item.type === MENU_ITEM_TYPES.contentList) {
                handleContentListMenuClick(item);
              }
            }}
          >
            {item.title}
          </MenuLink>
        );
      }

      if (item.type === MENU_ITEM_TYPES.link) {
        if (item.linkTarget === MENU_LINK_TARGET_TYPES.webview) {
          return (
            <MenuLink
              key={`webview-${item._id}`}
              to={{ name: 'webview', params: { id: item._id } }}
              data-content={item.title}
              active={location.name === 'webview' && location.params.id === item._id}
            >
              {item.title}
            </MenuLink>
          );
        }

        const target = item.linkTarget === MENU_LINK_TARGET_TYPES.blank ? '_blank' : undefined;

        return (
          <MenuLink
            key={item.url}
            href={item.url}
            target={target}
            data-content={item.title}
            active={currentUrl === item.url}
          >
            {item.title}
          </MenuLink>
        );
      }

      if (item.type === MENU_ITEM_TYPES.epg) {
        return (
          <MenuLink
            key="epg"
            to={{ name: 'epg' }}
            data-content={item.title}
            active={location.name === 'epg'}
          >
            {item.title}
          </MenuLink>
        );
      }

      if (item.type === MENU_ITEM_TYPES.contentList) {
        return (
          <MenuLink
            key={`collections-${item.identifier}`}
            to={{ name: 'collections', params: { id: item.identifier } }}
            data-content={item.title}
            active={location.name === 'collections' && location.params.id === item.identifier}
            onClick={() => handleContentListMenuClick(item)}
          >
            {item.title}
          </MenuLink>
        );
      }

      return null;
    }).map((link, i) => {
      if (!link) {
        return null;
      }

      const item = menu[i];

      return (
        <ClickTracker
          key={item.title}
          component="Menu"
          clickType="navigation"
          eventName={item.trackingEvent}
          collectionId={item.identifier}
        >
          {link}
        </ClickTracker>
      );
    });
  };

  return (
    <Nav
      position={position}
      isExpanded={isExpanded}
      {...(innerRef ? { innerRef } : {})}
    >
      <MenuContainer align="left" position={position}>
        {!hideItemsForUnentitled && renderMenuItems()}
      </MenuContainer>
      <MenuContainer align="right" position={position}>
        {
          withSearch && !isOffCanvas && (
            <ClickTracker
              component="Menu"
              clickType="navigation"
              eventName="Search"
            >
              <Search
                asLink={position === 'vertical'}
              />
            </ClickTracker>
          )
        }
        { /* MDM-14751 if off canvas, render search link to preserve some space */
          withSearch && isOffCanvas && (
            <SearchLink />
          )
        }
        {isLoggedIn && withSettings && (
          <ClickTracker
            component="Menu"
            clickType="navigation"
            eventName="Settings"
          >
            <MenuLink
              to={{ name: 'settings-account' }}
              data-content={settingsMenu.title}
              active={location.name.startsWith('settings')}
            >
              {settingsMenu.title}
            </MenuLink>
          </ClickTracker>
        )}

        {isLoggedIn && !withSettings && (
          <ClickTracker
            component="Menu"
            clickType="navigation"
            eventName="click_sign_out_button"
          >
            <MenuLink
              to={{ name: 'log-out' }}
              data-content={i18n.formatText('logOut')}
              className="e2e-log-out"
            >
              <Text id="logOut" />
            </MenuLink>
          </ClickTracker>
        )}
        {!isLoggedIn && (
          <ClickTracker
            component="Menu"
            clickType="navigation"
            eventName="Login"
          >
            <MenuLink
              to={{ name: 'log-in' }}
              className="e2e-log-in"
              data-content={i18n.formatText('logIn')}
              active={location.name === 'log-in'}
            >
              <Text id="logIn" />
            </MenuLink>
          </ClickTracker>
        )}
      </MenuContainer>
    </Nav>
  );
}

TopNavigation.propTypes = {
  position: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
  hideItemsForUnentitled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  innerRef: PropTypes.object,
  isOffCanvas: PropTypes.bool,
};

export default React.memo(TopNavigation);
