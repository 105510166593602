import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ChannelType } from 'types';
import { useDispatch } from 'react-redux';
import createComponent, { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import * as favoritesActions from 'actions/favorites';
import { setScrollableOffset } from 'actions/scrollable';
import { addMessage } from 'actions/messages';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import logger from 'utils/logger';
import { flattenEdges } from 'utils/helpers';
import { ClickTracker, useAnalytics } from 'components/Tracking';
import { Text } from 'components';
import Scrollable from 'components/Scrollable/Scrollable';
import ComponentWrapper, { ShowAllLink } from 'components/ComponentWrapper/ComponentWrapper';
import { useChannelsForChannelPickerQuery } from './myList.generated';

import LiveChannelItem from './Items/LiveChannelItem';
import ChannelPicker from './ChannelPicker/ChannelPicker';
import PencilIcon from './PencilIcon';
import { isShowAllBtn } from '../helper';

const LiveChannelEdit = createComponent(({ theme }: ComponentRuleProps): RuleStyles => ({
  backgroundColor: 'transparent',
  backgroundSize: 'contain',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  color: theme.color.primary,
  fontSize: theme.fontSize.normal,
  fontWeight: 'normal',
  ':hover': {
    fontWeight: 'bold',
  },
}), 'button');

type EditButtonProps = { onClick: () => void };

export const EditButton = ({ onClick }: EditButtonProps) => (
  <LiveChannelEdit onClick={onClick}><PencilIcon /> <Text id="edit" /></LiveChannelEdit>
);

EditButton.propTypes = {
  onClick: PropTypes.func,
};

const getNewChannelIndex = (collections: any = [], magineId: string, itemId: string) => {
  const index = collections
    .find((i: any) => i.node.magineId === magineId)
    ?.node.viewables.edges.findIndex((i: any) => i.node.id === itemId);

  return index ?? -1;
};

const ITEM_WIDTH = 322;

const blockTypes = [...Object.values(BLOCK_INTERFACE_TYPES)] as const;

type LiveChannelsProps = {
  title: string,
  pageId: string,
  collectionId: string,
  channels: ChannelType[],
  hasNextPage: boolean,
  withEdit?: boolean,
  hideChannelLogo?: boolean,
  refreshData: (id?: string) => Promise<any>,
  loadMore: () => void,
  categoryKind: typeof blockTypes,
};

const LiveChannels = (props: LiveChannelsProps) => {
  const {
    title,
    channels,
    refreshData,
    withEdit,
    hasNextPage,
    pageId,
    collectionId,
    categoryKind,
    hideChannelLogo,
    loadMore,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const { data } = useChannelsForChannelPickerQuery();

  const allChannels = useMemo(() => flattenEdges(
    data?.pickerChannels?.channels || {},
  ).filter(Boolean), [data]);

  const toggleEditMode = () => {
    const nextEditMode = !editMode;
    if (!nextEditMode) {
      analytics.onClick({
        component: 'ContentList',
        clickType: 'navigation',
        eventName: 'click_edit_button',
        collectionId,
        section: categoryKind,
      });
    }
    setEditMode(nextEditMode);
  };

  const addFavorite = async (id: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(favoritesActions.addFavorite(id));
      const res = await refreshData(collectionId);
      // scroll to added channel
      const updatedCollection = res?.[0]?.data.viewer.view.blocks.edges;
      const index = getNewChannelIndex(updatedCollection, collectionId, id);
      if (index >= 0) {
        dispatch(setScrollableOffset(pageId, `content-list-${collectionId}`, index * ITEM_WIDTH));
      }
    } catch (e) {
      dispatch(addMessage({ contentId: 'failedMessage' }));
      logger.error('Failed to add favorite', e);
    }
  };

  const removeFavorite = async (id: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(favoritesActions.removeFavorite(id));
      await refreshData();
    } catch (e) {
      dispatch(addMessage({ contentId: 'failedMessage' }));
      logger.error('Failed to remove favorite', e);
    }
  };

  const items = channels.map(channel => (
    <LiveChannelItem
      key={channel.id}
      channel={channel}
      refreshData={refreshData}
      collectionId={collectionId}
      categoryKind={categoryKind}
      hideChannelLogo={hideChannelLogo}
      editMode={editMode}
    />
  ));

  const showAllLink = isShowAllBtn(hasNextPage, items) ? (
    <ClickTracker
      component="ContentList"
      clickType="navigation"
      eventName="click_see_all_button"
      section={categoryKind}
      collectionId={collectionId}
    >
      <ShowAllLink to={{ name: 'collection', params: { id: collectionId } }}>
        <Text id="showAll" />
      </ShowAllLink>
    </ClickTracker>
  ) : null;

  // Scrollable should be converted to func but it uses prevState
  const ScrollableComponent = Scrollable as any;

  return (
    <ComponentWrapper
      title={title}
      right={(withEdit && <EditButton onClick={toggleEditMode} />)
      || showAllLink}
    >
      <ScrollableComponent
        pageId={pageId}
        id={`content-list-${collectionId}`}
        loadMore={loadMore}
        hasMoreLoad={hasNextPage}
        itemWidthRem={1}
        itemWidthPx={292}
      >
        {items}
      </ScrollableComponent>
      {editMode && (
        <ChannelPicker
          favoriteChannelIds={channels.map(c => c.id)}
          channels={allChannels}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
        />
      )}
    </ComponentWrapper>
  );
};

export default React.memo(LiveChannels);
