// @ts-nocheck
import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
export type TViewableFragment_Channel_Fragment = { __typename?: 'Channel', title?: string | null, image?: string | null, id: string, poster?: string | null, banner?: string | null };

export type TViewableFragment_Episode_Fragment = { __typename?: 'Episode', title?: string | null, image?: string | null, id: string, poster?: string | null, banner?: string | null };

export type TViewableFragment_Show_Fragment = { __typename?: 'Show', title?: string | null, image?: string | null, id: string, poster?: string | null, banner?: string | null };

export type TViewableFragment_Trailer_Fragment = { __typename?: 'Trailer', title?: string | null, image?: string | null, id: string, poster?: string | null, banner?: string | null };

export type TViewableFragment_Movie_Fragment = { __typename?: 'Movie', title?: string | null, image?: string | null, id: string, poster?: string | null, banner?: string | null };

export type TViewableFragment_Program_Fragment = { __typename?: 'Program', title?: string | null, image?: string | null, id: string, poster?: string | null, banner?: string | null };

export type TViewableFragmentFragment = TViewableFragment_Channel_Fragment | TViewableFragment_Episode_Fragment | TViewableFragment_Show_Fragment | TViewableFragment_Trailer_Fragment | TViewableFragment_Movie_Fragment | TViewableFragment_Program_Fragment;

export const ViewableFragmentFragmentDoc = gql`
    fragment ViewableFragment on ViewableInterface {
  id: magineId
  title
  poster: image(type: "poster")
  banner: image(type: "sixteen-nine")
  image
}
    `;