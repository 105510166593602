import React from 'react';
import PropTypes from 'prop-types';
import { BLOCK_INTERFACE_TYPES, WATCHLIST_TYPES } from 'utils/constants';
import { Text, imageTypes } from 'components';
import Scrollable from 'components/Scrollable/Scrollable';
import { TooltipTrigger } from 'components/HoverState';
import ComponentWrapper, {
  ShowAllLink,
} from 'components/ComponentWrapper/ComponentWrapper';
import { ClickTracker } from 'components/Tracking';
import PosterItem from 'components/VideoItems/PosterItem';
import { viewableShape } from '../prop-types';
import { isShowAllBtn } from '../helper';

const Watchlist = ({
  pageId,
  collectionId,
  categoryKind,
  title,
  viewables,
  hasNextPage,
  refreshData,
  loadMore,
}) => {
  if (!viewables.length) {
    return null;
  }

  const items = viewables.filter(
    viewable => WATCHLIST_TYPES.includes(viewable.__typename),
  ).map(viewable => (
    <ClickTracker
      key={viewable.id}
      component="ContentList"
      eventName="click_asset"
      clickType="asset"
      collectionId={collectionId}
      categoryKind={categoryKind}
      viewableId={viewable.id}
    >
      <TooltipTrigger
        viewable={viewable}
        refreshData={refreshData}
        collectionId={collectionId}
        categoryKind={categoryKind}
      >
        <PosterItem viewable={viewable} />
      </TooltipTrigger>
    </ClickTracker>
  ));

  const showAllLink = isShowAllBtn(hasNextPage, items) ? (
    <ClickTracker
      component="ContentList"
      clickType="navigation"
      eventName="click_see_all_button"
      section={categoryKind}
      collectionId={collectionId}
    >
      <ShowAllLink to={{ name: 'collection', params: { id: collectionId } }}>
        <Text id="showAll" />
      </ShowAllLink>
    </ClickTracker>
  ) : null;

  return (
    <ComponentWrapper
      title={title}
      right={showAllLink}
    >
      <Scrollable
        pageId={pageId}
        id={`watchlist-${collectionId}`}
        scrollToNewItem
        loadMore={loadMore}
        hasMoreLoad={hasNextPage}
        itemWidthRem={imageTypes.poster.width}
      >
        {items}
      </Scrollable>
    </ComponentWrapper>
  );
};

Watchlist.propTypes = {
  pageId: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  categoryKind: PropTypes.oneOf(Object.values(BLOCK_INTERFACE_TYPES)).isRequired,
  title: PropTypes.string.isRequired,
  viewables: PropTypes.arrayOf(viewableShape).isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
};

export default Watchlist;
