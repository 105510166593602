import React from 'react';
import PropTypes from 'prop-types';
import {
  Spinner,
} from 'components';
import createComponent from 'styles/fela/createComponent';

const Container = createComponent(({ height }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: `${height}px`,
  margin: 'auto',
  width: '100%',
}), 'div', ['height']);

export default function PlaceholderWithSpinner({ height, size = 3 }) {
  return (
    <Container height={height}>
      <Spinner size={size} />
    </Container>
  );
}

PlaceholderWithSpinner.propTypes = {
  size: PropTypes.number,
  height: PropTypes.number.isRequired,
};

PlaceholderWithSpinner.defaultProps = {
  size: 3,
};
