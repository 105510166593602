import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from 'components';
import { FooterImage, FooterImageCont, FooterImageWrapper } from './FooterStyles';

function FooterImages({ oneRow }) {
  return (
    <FooterImageWrapper mb="medium" display="flex">
      { oneRow && oneRow.length > 0 && oneRow.map((img, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FooterImageCont key={index}>
          {img.linkUrl ? (
            <Link width={img.imageWidth || 'auto'} href={img.linkUrl} target="_blank">
              <FooterImage alt="Footer link" width={img.imageWidth} src={img.logoUrl} />
            </Link>
          ) : (
            <FooterImage alt="Footer item" width={img.imageWidth} src={img.logoUrl} />
          )}
        </FooterImageCont>
      ))}
    </FooterImageWrapper>
  );
}

FooterImages.propTypes = {
  oneRow: PropTypes.array.isRequired,
};

export default FooterImages;
