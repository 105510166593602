import {
  OFFER_TYPES,
} from 'utils/constants';
import { createRedirectLink } from 'utils/routing';
import { generateWatchLocation } from 'utils/helpers';

export const MSG_IDS = {
  [OFFER_TYPES.SubscribeType]: 'checkout.subscriptionOffers',
  [OFFER_TYPES.RentType]: 'checkout.rentOffers',
  [OFFER_TYPES.BuyType]: 'checkout.buyOffers',
  [OFFER_TYPES.PassType]: 'checkout.passOffers',
};

export const generateCheckoutConfLocation = (offerId, viewable, redirectTo, promoCode) => ({
  name: 'checkout-confirmation',
  query: {
    packageId: offerId,
    redirectTo: redirectTo || createRedirectLink(generateWatchLocation(viewable)),
    ...(promoCode ? { promoCode } : null),
  },
});
