// @ts-nocheck
import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TApplyPromoCodeMutationVariables = Types.Exact<{
  promoCode: Types.Scalars['String'];
}>;


export type TApplyPromoCodeMutation = { __typename?: 'Mutation', applyPromoCode?: { __typename?: 'ApplyPromocodePayload', offerId?: string | null } | null };


export const ApplyPromoCodeDocument = gql`
    mutation applyPromoCode($promoCode: String!) {
  applyPromoCode(input: {clientMutationId: "", promocode: $promoCode}) {
    offerId
  }
}
    `;
export type TApplyPromoCodeMutationFn = Apollo.MutationFunction<TApplyPromoCodeMutation, TApplyPromoCodeMutationVariables>;

/**
 * __useApplyPromoCodeMutation__
 *
 * To run a mutation, you first call `useApplyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoCodeMutation, { data, loading, error }] = useApplyPromoCodeMutation({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useApplyPromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<TApplyPromoCodeMutation, TApplyPromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TApplyPromoCodeMutation, TApplyPromoCodeMutationVariables>(ApplyPromoCodeDocument, options);
      }
export type ApplyPromoCodeMutationHookResult = ReturnType<typeof useApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationResult = Apollo.MutationResult<TApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationOptions = Apollo.BaseMutationOptions<TApplyPromoCodeMutation, TApplyPromoCodeMutationVariables>;