import * as Cookies from 'js-cookie';
import storage, { sessionStorage } from 'utils/storage';
import logger from 'utils/logger';
import { Query, RouterLocation } from 'router';
import * as routerActions from 'router/actions';
import * as messageActions from './messages';
import * as campaignActions from './campaign';
import * as offersActions from './offers';
import { apiNetworkRequest, networkRequest, AppAction, AppAsyncAction } from './helpers';

export const SET_AUTH = 'auth/SET_AUTH';

interface SetAuthAction {
  type: typeof SET_AUTH
  sessionToken: string
  userId: string
}

export const setAuth = (sessionToken: string, userId: string): SetAuthAction => ({
  type: SET_AUTH,
  sessionToken,
  userId,
});

export function authenticate(userId: string, token: string): SetAuthAction {
  const isHttps = window.location.protocol === 'https:';
  const options = {
    // TODO: `expires` should probably not be hard-coded and
    // instead correlate to how long the JWT token are valid.
    expires: 14, // in days
    secure: isHttps,
    sameSite: isHttps ? 'None' as const : undefined,
  };

  Cookies.set('userId', userId, options);
  Cookies.set('token', token, options);

  return setAuth(token, userId);
}

export function openInitialView(): AppAction {
  return (dispatch, getState) => {
    const { redirectTo = null } = getState().router.query;
    let to = { name: 'home' };

    if (typeof redirectTo === 'string') {
      try {
        to = JSON.parse(redirectTo) || { name: 'home' };
        to.name = to.name || 'home';
      } catch (e) {
        logger.error('Failed to parse redirectTo', e);
      }
    }

    return dispatch(routerActions.push(to));
  };
}

export function getCheckoutFirstRoute(query: Query): AppAsyncAction<RouterLocation> {
  return async (dispatch, getState) => {
    await dispatch(offersActions.fetchPurchaseFlowOffers());
    const { offers } = getState();

    const predefinedOffer = offers.find(offer => offer.id === query.packageId);

    if (!!query.packageId && !predefinedOffer) {
      dispatch(messageActions.addMessage({
        contentId: 'offer.noAvailableInRegion',
        duration: 10,
      }));

      return {
        name: 'checkout',
        query: {
          ...query,
          packageId: undefined,
        },
      };
    }

    if (predefinedOffer && !query.promoCode) {
      return {
        name: 'checkout-confirmation',
        query,
      };
    }

    if (predefinedOffer && query.promoCode) {
      try {
        const validationResult = await dispatch(
          campaignActions.validatePromocode((query.packageId || '').toString(), query.promoCode.toString()),
        );

        if (validationResult.valid) {
          return {
            name: 'checkout-confirmation',
            query,
          };
        }

        return {
          name: 'checkout',
          query: {
            ...query,
            packageId: undefined,
          },
        };
      } catch (e) {
        return {
          name: 'checkout',
          query: {
            ...query,
            promoCode: undefined,
          },
        };
      }
    }

    if (offers.length === 1) {
      const packageId = offers[0].id;
      return {
        name: 'checkout-confirmation',
        query: {
          ...query,
          packageId,
        },
      };
    }

    return {
      name: 'checkout',
      query,
    };
  };
}

export function openPurchaseFlow(query: Query): AppAsyncAction {
  return async (dispatch, getState) => {
    const state = getState();

    const route = await dispatch(getCheckoutFirstRoute({
      ...state.router.query,
      ...query,
    }));

    dispatch(routerActions.push(route));
  };
}

export function login(fields: any, doAuthenticate = authenticate): AppAsyncAction {
  return async (dispatch, getState) => {
    const { loginMethod } = getState().settings.features;

    const response = await dispatch(networkRequest(
      loginMethod.url,
      loginMethod.httpMethod,
      {},
      fields,
    ));

    if (!response.ok) {
      throw new Error(`Login request failed: ${response.status} ${response.statusText}`);
    }

    const {
      userId,
      token,
    } = await response.json();

    dispatch(doAuthenticate(userId, token));
  };
}

export const LOGOUT = 'auth/LOGOUT';

interface LogoutAction {
  type: typeof LOGOUT
}

export function logout(inBrowser: boolean): LogoutAction {
  // `Cookies` only works in the browser.
  // If this is triggered server-side the
  // expiration happens inside of magine-web's
  // onMatch callback.
  if (inBrowser) {
    Cookies.remove('userId');
    Cookies.remove('token');
    // API server set this cookies which are equal to token and userId
    // so we should remove them too
    Cookies.remove('mg_session_id');
    Cookies.remove('mg_user_id');

    sessionStorage.removeItem('paymentWarningNotificationShown');
    storage.clear();
  }

  return { type: LOGOUT };
}

export function requestPasswordChange(fields: any): AppAsyncAction<Response> {
  return async (dispatch, getState) => {
    const { language } = getState().settings.l10n;
    const { forgotPassword } = getState().settings.features.loginMethod;

    const response = await dispatch(networkRequest(
      forgotPassword.url,
      forgotPassword.httpMethod,
      {},
      {
        locale: language,
        ...fields,
      },
    ));

    return response;
  };
}

export function resetPassword(token: string, password: string): AppAsyncAction {
  return async (dispatch) => {
    await dispatch(apiNetworkRequest('auth.resetPassword', { token, password }));
  };
}

type SignupParams = {
  username: string,
  password: string,
  locale: string,
  geoipCountry: string,
  emailOptIn: boolean,
  name?: string,
  captcha?: {
    reCaptchaToken: string,
  },
};

export function signup(
  params: SignupParams,
  doAuthenticate = authenticate,
): AppAsyncAction {
  return async (dispatch, getState) => {
    const { registerMethods } = getState().settings.features;

    const {
      username: email,
      password,
      locale,
      geoipCountry,
      emailOptIn,
      name,
      ...dynamic
    } = params;

    const response = await dispatch(networkRequest(
      registerMethods.url,
      registerMethods.httpMethod,
      {},
      {
        identity: email,
        name: name || email, // there is no name for default SignIn but is for Social Account
        password,
        email,
        locale,
        country: geoipCountry,
        emailOptIn,
        ...dynamic,
      },
    ));

    const { userId, token } = await response.json();

    if (!response.ok && !userId && !token) {
      const error = new Error(`Signup request failed: ${response.status} ${response.statusText}`);
      throw error;
    }

    dispatch(doAuthenticate(userId, token));
  };
}

export type AuthActions = SetAuthAction | LogoutAction;
