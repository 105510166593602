import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as commonActions from 'actions/common';
import ErrorPage, { ERROR_ICONS } from 'components/ErrorPage/ErrorPage';
import { ButtonType } from 'components/ErrorPage/constants';
import GeneralErrorPage from 'components/ErrorPage/GeneralErrorPage';
import { I18nProvider } from 'components/I18n';
import { AnalyticsProvider, PageViewTracker } from 'components/Tracking';
import { PreviewPlayerProvider } from 'components/PreviewPlayer';
import {
  Metadata,
  Text,
  Box,
} from 'components';
import logger from 'utils/logger';
import { useOnlineStatus } from 'utils/hooks';
import initHotJar from 'components/Tracking/hotjar';
import {
  PlayerProvider,
  PlayerContainer,
} from 'components/Player';
import Messages from 'components/Messages/Messages';
import PromoBanner from 'components/PromoBanner';
import Nielsen from 'components/Nielsen';
import { useRouter } from 'router';
import { RecaptchaTracker } from 'components/Recaptcha/RecaptchaTracker';
import AppInfo from 'components/AppInfo';
import { useAppInfo } from 'components/AppInfo/useAppInfo';
import LiteTooltip from 'components/LiteTooltip';
import CookieTool from 'components/CookieTool/CookieTool';
import ErrorHandler from './ErrorHandler';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import LoadingBar from './LoadingBar/LoadingBar';
import ServiceNotAvailableNotification from './ServiceNotAvailableNotification';
import CookiePolicyNotification from './CookiePolicyNotification';
import IEDeprecationNotification from './IEDeprecationNotification';
import DeviceNotSupportedNotification from './DeviceNotSupportedNotification';
import PaymentWarningNotification from './PaymentWarningNotification';
import {
  App,
  Main,
  Content,
} from './AppStyles';

const AppView = ({ children }) => {
  const dispatch = useDispatch();
  const {
    locale,
    routeError,
    routeLoading,
    hotjar,
    useNielsen,
  } = useSelector(state => ({
    locale: state.settings.l10n.language,
    routeError: state.router.routeError,
    routeLoading: state.router.routeLoading,
    hotjar: state.settings.features.hotjar,
    useNielsen: !!state.settings.features.mms?.nielsenAppId,
  }), shallowEqual);

  const {
    isDevToolbarEnabled,
  } = useAppInfo();

  const [hasError, setHasError] = useState(false);
  const isOnline = useOnlineStatus();
  const router = useRouter();

  const onError = () => {
    setHasError(true);
  };

  useEffect(() => {
    if (hotjar) {
      initHotJar(hotjar.id);
    }

    // this helps our e2e tests to know when app is ready for testing
    window.e2eReady = true;

    dispatch(commonActions.setScrollBarWidth());
  }, []);

  useEffect(() => {
    // log router error
    if (routeError) {
      logger.error('ROUTE_ERROR:', routeError);
    }
  }, [routeError]);

  useEffect(() => {
    // reset error state after receiving new child view
    if (hasError) {
      setHasError(false);
    }
  }, [children]);

  let content;
  if (routeError || hasError) {
    content = (
      <GeneralErrorPage />
    );
  } else {
    content = children;
  }

  const activeRoute = router.getActiveRoute();
  if (!activeRoute) {
    return null;
  }

  return (
    <ErrorHandler onError={onError}>
      <I18nProvider language={locale}>
        <CookieTool>
          <AnalyticsProvider>
            <PlayerProvider>
              <PreviewPlayerProvider>
                <App
                  className="e2e-appview"
                  fixedHeight={activeRoute.layout.hideScroll}
                >
                  <PageViewTracker pageAnalytics={activeRoute.pageAnalytics} />
                  <RecaptchaTracker />
                  <Metadata />
                  <LoadingBar loading={routeLoading} />

                  <Header />

                  <Main fixedHeight={activeRoute.layout.hideScroll}>
                    <div id="tooltip" />
                    <LiteTooltip id="main" />

                    <div id="modal" />

                    <PlayerContainer isOnline={isOnline} />
                    <ServiceNotAvailableNotification />
                    <DeviceNotSupportedNotification />
                    <CookiePolicyNotification />
                    <IEDeprecationNotification />
                    <PaymentWarningNotification />
                    <Messages />
                    <PromoBanner />

                    {useNielsen && (
                      <Nielsen />
                    )}

                    <Content>
                      {content}
                    </Content>

                    <Box
                      hidden={isOnline}
                      column
                      fullHeight
                      fullWidth
                      minHeight="inherit"
                      justifyContent="center"
                      position="fixed"
                      zIndex="1000"
                      bg="inherit"
                      top="0"
                    >
                      <ErrorPage button={ButtonType.RETRY} icon={ERROR_ICONS.NO_CONNECTION}>
                        <Text id="error.noInternet" isHtml />
                      </ErrorPage>
                    </Box>
                    {isDevToolbarEnabled() && (
                      <AppInfo />
                    )}
                  </Main>
                  {activeRoute.layout.hideFooter || (
                    <Footer />
                  )}
                </App>
              </PreviewPlayerProvider>
            </PlayerProvider>
          </AnalyticsProvider>
        </CookieTool>
      </I18nProvider>
    </ErrorHandler>
  );
};

AppView.propTypes = {
  children: PropTypes.node,
};

export default React.memo(AppView);
